import { Controller } from "@hotwired/stimulus"

/**
 * The Sortable Chars controller provides a way to update a letter
 * character label after a sortable list is reordered.
 *
 * Example:
 *
 *   <div
 *     data-controller="sortable sortable-chars"
 *     data-action="sortable:end->sortable-chars#update"
 *   >
 *     <div data-sortable-chars-target="char">A</div>
 *     <div data-sortable-chars-target="char">B</div>
 *     <div data-sortable-chars-target="char">C</div>
 *   </div>
 */
export default class extends Controller {
  static targets = ["char"]

  update() {
    if (this.hasCharTarget) {
      let i = 1
      for (const char of this.charTargets) {
        // Only adjust when char is visible
        if (char.offsetParent !== null) {
          char.innerText = String.fromCharCode(i + 96)
          i++
        }
      }
    }
  }
}
