import { Controller } from "@hotwired/stimulus"

// This controller is used to ensure that the active element is visible within
// the scrollable container. If a the target "active" element is not within the
// viewport, the container will scroll to make it visible. We use this on the
// lesson question navigation to ensure that the active number question is in
// view.
// Currently only supports scrolling from the left.

// Connects to data-controller="scroll-to-active"
export default class extends Controller {
  static targets = ["active"]
  static values = { offsetEnd: { type: Number, default: 0 } }

  connect() {
    this.scrollToActive()
  }

  get offsetLeft() {
    const elementBounds = this.element.getBoundingClientRect()
    const targetBounds = this.activeTarget.getBoundingClientRect()

    if (targetBounds.right > elementBounds.right) {
      return targetBounds.right - elementBounds.right
    }

    // if (targetBounds.left < elementBounds.left) {
    //   return targetBounds.left - elementBounds.left
    // }

    return 0
  }

  scrollToActive() {
    if (!this.hasActiveTarget || this.offsetLeft === 0) {
      return
    }

    setTimeout(() => {
      this.element.scrollTo({
        left: this.offsetLeft + this.offsetEndValue,
        behavior: "smooth",
      })
    }, 100)
  }
}
