import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

// Connects to data-controller="admin--date-range-chooser"
export default class extends Controller {
  static targets = [
    "startDate",
    "endDate",
    "startDateInput",
    "endDateInput",
    "customRangeForm",
  ]

  connect() {
    useClickOutside(this, {
      target: this.customRangeFormTarget,
      eventPrefix: "customRange",
    })
  }

  updateDateRange({ params }) {
    this.startDateTarget.textContent = params.startdate
    this.endDateTarget.textContent = params.enddate
  }

  showCustomRange() {
    this.customRangeFormTarget.classList.remove("invisible")
  }

  hideCustomRange() {
    this.customRangeFormTarget.classList.add("invisible")
  }
}
