import { Controller } from "@hotwired/stimulus"
import { Sortable, MultiDrag } from "sortablejs"
import { patch } from "@rails/request.js"

// Connects to data-controller="sortable"
export default class extends Controller {
  static values = {
    url: String,
    handle: String,
    multiDrag: Boolean,
    options: Object,
    selectedClass: String,
    rankedModel: { type: Boolean, default: false },
  }

  initialize() {
    if (this.multiDragValue) {
      try {
        Sortable.mount(new MultiDrag())
      } catch (error) {
        console.log(error)
      }
    }
    this.onUpdate = this.onUpdate.bind(this)
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
      onEnd: this.end.bind(this),
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  end(event) {
    // This sends a simpler request to update models that use ranked-model
    if (this.rankedModelValue) {
      let items =
        event.items && event.items.length > 0 ? event.items : [event.item]
      let ids = items.map(item => item.dataset.id)
      let positions = items.map((_, index) => event.newIndex + index)

      let data = new FormData()
      data.append("lesson[ids]", JSON.stringify(ids))
      data.append("lesson[featured_order_positions]", JSON.stringify(positions))

      fetch(this.urlValue, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          Accept: "application/json",
        },
        body: data,
      })
    }

    this.dispatch("end", { detail: { event } })
  }

  onUpdate = async event => {
    if (!this.urlValue) return

    // This is the old format that updates models that use acts_as_list
    if (!this.rankedModelValue) {
      const data = this.serialize(this.element)

      await patch(this.urlValue + "?" + data, {
        contentType: "text/javascript",
      })
    }

    this.dispatch("update", { detail: { event } })
  }

  // Borrowed from JQuery UI Sortable
  serialize(o) {
    var items = o.children,
      str = []
    o = o || {}

    $(items).each(function () {
      var res = ($(o.item || this).attr(o.attribute || "id") || "").match(
        o.expression || /(.+)[\-=_](.+)/,
      )
      if (res) {
        str.push(
          (o.key || res[1] + "[]") +
            "=" +
            (o.key && o.expression ? res[1] : res[2]),
        )
      }
    })

    if (!str.length && o.key) {
      str.push(o.key + "=")
    }

    return str.join("&")
  }

  get defaultOptions() {
    return {
      multiDrag: this.multiDragValue || false,
      selectedClass: this.selectedClassValue || "sortable-selected",
      handle: this.handleValue || undefined,
      onUpdate: this.onUpdate,
      onEnd: this.onEnd,
    }
  }
}
