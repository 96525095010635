// Dropdown menu used by lessons filters
let mobileWidth = undefined
window.App.Components.TededDropdown = class TededDropdown {
  constructor() {
    mobileWidth = 480
    this.panelOpen = this.panelOpen.bind(this)
    this.panelClose = this.panelClose.bind(this)
    this.dropdownToggle = this.dropdownToggle.bind(this)
    this.$body = $("body")
    this.injectAriaAttributes()
    // To Do: keyboard accessibility
    this.addEventListeners()
    this.bindElements()
    this.bindResizeSensor()
  }

  destroy() {
    this.$body.off("click.tededdropdown touchend.tededdropdown")
    this.$body.off("click.tededdropdown")
  }

  injectAriaAttributes() {
    $('[data-dropdown="toggle"],[data-dropdown="panel-open"]').attr(
      "aria-haspopup",
      "true",
    )

    return $('[data-dropdown="toggle"]').each((i, el) => {
      const $el = $(el)
      const menuId = TededDropdown.getMenuId($el)
      const menuHidden = $(menuId).attr("aria-hidden")
      const expanded = menuHidden === "false" ? "true" : "false"
      return $(el).attr("aria-expanded", expanded)
    })
  }

  addEventListeners() {
    return document.addEventListener(
      "tededdropdown.close",
      function (e) {
        if (e.detail.menuId != null) {
          return TededDropdown.closeMenu(e.detail.menuId)
        }
      },
      false,
    )
  }

  bindElements() {
    this.$body.on(
      "click.tededdropdown touchend.tededdropdown",
      '[data-dropdown="toggle"]',
      this.dropdownToggle,
    )

    this.$body.on(
      "click.tededdropdown",
      '[data-dropdown="panel-open"]',
      this.panelOpen,
    )

    return this.$body.on(
      "click.tededdropdown",
      '[data-dropdown="panel-close"]',
      this.panelClose,
    )
  }

  bindResizeSensor() {
    return $("[data-dropdown-anchor]").each(function () {
      const el = this
      if ($(el).data("resize-off")) {
        return
      }
      return new ResizeSensor(el, function () {
        const $control = $(el).find('[data-dropdown="toggle"]')
        const menuId = TededDropdown.getMenuId($control)
        if (menuId != null) {
          return TededDropdown.checkOffscreenBounds(menuId)
        }
      })
    })
  }

  panelOpen(e) {
    e.preventDefault()
    const menuId = TededDropdown.getMenuId($(e.currentTarget))
    $(menuId)
      .closest('[data-dropdown="panel"]')
      .css("transform", "translate3d(-100%, 0, 0)")
    return TededDropdown.openMenu(menuId)
  }

  panelClose(e) {
    e.preventDefault()
    const menuId = TededDropdown.getMenuId($(e.currentTarget))
    $(menuId)
      .closest('[data-dropdown="panel"]')
      .css("transform", "translate3d(0%, 0, 0)")
    return TededDropdown.closeMenu(menuId)
  }

  dropdownToggle(e) {
    e.preventDefault()
    const $el = $(e.currentTarget)
    const menuId = TededDropdown.getMenuId($el)
    // If no valid menu exists return early.
    if ($(menuId).length < 1) {
      return false
    }

    if ($el.attr("aria-expanded") === "true") {
      return TededDropdown.closeMenu(menuId)
    } else {
      TededDropdown.openMenu(menuId)
      TededDropdown.checkOffscreenBounds(
        '[data-dropdown="menu"][aria-hidden="false"]',
      )
      return setTimeout(() => {
        if ($(menuId).attr("aria-hidden") === "false") {
          return TededDropdown.bindOutsideClick(menuId)
        }
      }, 200)
    }
  }

  static closeMenu(menuId) {
    const $menu = $(menuId)
    $menu.attr("aria-hidden", "true")
    $(`[href='${menuId}'][data-dropdown='toggle']`).removeClass(
      "tdd-dropdown--open",
    )
    $menu.find('[aria-hidden="false"]').attr("aria-hidden", true)
    $menu.find('[aria-expanded="true"]').attr("aria-expanded", false)
    $menu
      .find('[data-dropdown="panel"]')
      .css("transform", "translate3d(0%, 0, 0)")
    $(`[href='${menuId}'][data-dropdown]`).attr("aria-expanded", "false")
    return TededDropdown.unbindOutsideClick(menuId)
  }

  static openMenu(menuId) {
    const $menu = $(menuId)
    $menu.attr("aria-hidden", "false")
    $(`[href='${menuId}'][data-dropdown='toggle']`).addClass(
      "tdd-dropdown--open",
    )
    return $(`[href='${menuId}'][data-dropdown]`).attr("aria-expanded", "true")
  }

  static bindOutsideClick(menuId) {
    return $("html").one(
      `click.tededdropdown.${menuId} touchend.tededdropdown.${menuId}`,
      e => {
        if ($(menuId).has(e.target).length < 1) {
          return TededDropdown.closeMenu(menuId)
        } else {
          return TededDropdown.bindOutsideClick(menuId)
        }
      },
    )
  }

  static unbindOutsideClick(menuId) {
    return $("html").off(
      `click.tededdropdown.${menuId} touchend.tededdropdown.${menuId}`,
    )
  }

  static getMenuId($control) {
    return $control.attr("href") || $control.data("menu")
  }

  static checkOffscreenBounds(selector) {
    let offset
    const $menu = $(selector)
    const $anchor = $menu.closest("[data-dropdown-anchor]")

    if ($anchor.data("resize-off")) {
      return
    }

    // On mobile dropdowns are full screen width and pinned to the left.
    if (window.innerWidth <= mobileWidth) {
      const parentOffset = $menu.closest("[data-dropdown-anchor]").offset().left
      $menu.css({
        transform: "",
        left: parentOffset * -1,
      })
      return
    }

    // reset the transform before calculating the position.
    $menu.get(0).style.removeProperty("left")
    $menu.css("transform", "translate3d(0, 0, 0)")

    const leftbound = $menu.offset().left || 0
    const rightBound = leftbound + $menu.width()

    if (leftbound < 0) {
      offset = Math.floor(leftbound - 10) * -1
    } else if (rightBound >= window.innerWidth) {
      offset = Math.ceil(window.innerWidth - rightBound - 30)
    }

    return $menu.css("transform", `translate3d(${offset}px, 0, 0)`)
  }
}

// Avoid double-loading on turbo page loads
let dropdownInstance = null

function initializeDropdown() {
  if ($('[data-dropdown="menu"]').length > 0 && !dropdownInstance) {
    dropdownInstance = new App.Components.TededDropdown()
  }
}

function destroyDropdown() {
  if (dropdownInstance) {
    dropdownInstance.destroy()
    dropdownInstance = null
  }
}

$(document).on("turbo:load", function () {
  destroyDropdown()
  initializeDropdown()
})

$(document).on("turbo:before-cache", function () {
  destroyDropdown()
})
