import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "progress"]

  static values = {
    current: Number,
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  }

  currentValueChanged = (value, previousValue) => {
    this.inputTarget.value = value
    this.setProgress()
    this.dispatch("change", { detail: { value, previousValue } })
  }

  onInput() {
    this.currentValue = parseInt(this.inputTarget.value)

    this.setProgress()
  }

  setProgress = () => {
    const percent = Math.max(
      0,
      (this.currentValue - this.minValue) / (this.maxValue - this.minValue)
    )
    this.progressTarget.style.left = `${percent * 100}%`
  }
}
