import { Controller } from "@hotwired/stimulus"
import { useClickOutside, useHover } from "stimulus-use"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["button", "menu", "activeMark"]

  static values = {
    group: String,
    fixed: { type: Boolean, default: false },
    hover: { type: Boolean, default: true },
  }

  initialize() {
    this.closeTimer = null
    this.isHovering = false
    this.isOpen = this.element.dataset?.open === "true"
  }

  connect() {
    if (this.hoverValue !== false) {
      useHover(this, { element: this.element })
    }

    useClickOutside(this)

    if (this.fixedValue) {
      this.menuTarget.classList.remove("absolute")
      this.menuTarget.classList.add("fixed")

      this.boundPositionFixedMenu = this.positionFixedMenu.bind(this)
      window.addEventListener("scroll", this.boundPositionFixedMenu)
    }

    this.element[this.identifier] = this

    this.onFocusOut = this.focusOut.bind(this)
    this.onFocusIn = this.focusIn.bind(this)

    this.element.addEventListener("focusout", this.onFocusOut)
    this.element.addEventListener("focusin", this.onFocusIn)

    this.toggleVisibility()
  }

  disconnect() {
    this.element.addEventListener("focusout", this.onFocusOut)
    this.element.addEventListener("focusin", this.onFocusIn)

    if (this.fixedValue) {
      window.removeEventListener("scroll", this.boundPositionFixedMenu)
    }
  }

  toggleVisibility() {
    this.menuTarget.classList.toggle("block", this.isOpen)
    this.menuTarget.classList.toggle("hidden", !this.isOpen)

    if (this.fixedValue) {
      this.positionFixedMenu()
    }

    if (this.hasActiveMarkTarget) {
      this.activeMarkTarget.classList.toggle("block", this.isOpen)
      this.activeMarkTarget.classList.toggle("hidden", !this.isOpen)
    }

    this.adjustBottomMargin()

    this.buttonTarget.setAttribute(
      "aria-expanded",
      this.isOpen ? "true" : "false",
    )
  }

  openMenu() {
    this.isOpen = true
    this.dispatch("open", { detail: { group: this.groupValue } })
    this.toggleVisibility()
  }

  closeMenu(event) {
    this.isOpen = false
    this.stopClose()
    this.toggleVisibility()

    // Return focus to the button when the menu is closed via keyboard navigation
    if (event?.type === "keydown") {
      this.buttonTarget.focus()
    } else {
      this.buttonTarget.blur()
    }
  }

  toggleMenu() {
    if (this.isHovering) {
      return
    }

    if (this.buttonTarget.getAttribute("aria-expanded") === "true") {
      this.closeMenu()
    } else {
      this.openMenu()
    }
  }

  // Using a fixed position will allow the dropdown to
  // overflow a parent with `overflow: hidden` or `overflow: auto`
  positionFixedMenu() {
    if (!this.isOpen || !this.fixedValue) {
      return
    }

    const {
      top,
      height,
      left,
      width: buttonWidth,
    } = this.buttonTarget.getBoundingClientRect()
    const { width: menuWidth } = this.menuTarget.getBoundingClientRect()

    this.menuTarget.style.position = "fixed"
    this.menuTarget.style.top = `${top + height}px`
    this.menuTarget.style.left = `${left - menuWidth + buttonWidth}px`
  }

  // Prevent a dropdown from overflowing the page by adjusting
  // the bottom margin to accomodate the overflow.
  adjustBottomMargin() {
    if (this.isOpen) {
      const { bottom } = this.menuTarget.getBoundingClientRect()
      const overflowDistance = bottom - window.innerHeight

      if (overflowDistance > 0) {
        // Add an extra 10px to give a little space below the menu
        document.body.style.marginBottom = `${overflowDistance + 10}px`
      }
    } else {
      document.body.style.marginBottom = "0px"
    }
  }

  delayedClose(timeout) {
    clearTimeout(this.closeTimer)
    this.closeTimer = setTimeout(() => {
      this.closeMenu()
    }, timeout)
  }

  stopClose() {
    clearTimeout(this.closeTimer)
  }

  mouseEnter() {
    this.isHovering = true
    this.stopClose()
    this.openMenu()
  }

  mouseLeave() {
    this.isHovering = false
    this.delayedClose(500)
  }

  focusIn() {
    this.stopClose()
  }

  focusOut() {
    this.delayedClose(100)
  }

  clickOutside() {
    this.closeMenu()
  }

  groupClose({ target, detail: { group } }) {
    if (this.element !== target && group === this.groupValue) {
      this.closeMenu()
    }
  }
}
