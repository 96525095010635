import { useMatchMedia } from "stimulus-use"
import get from "lodash.get"

export class UseCapsulePlayer {
  constructor(controller, options = {}) {
    this.controller = controller
    this.controller.values = {
      ...this.controller.values,
      slug: String,
      animated: { type: Boolean, default: true },
    }

    this.playerRoot = get(options, "element", controller.element)

    this.onConnect()
    this.enhanceController()
  }

  onConnect() {
    useMatchMedia(this.controller, {
      mediaQueries: {
        reducedMotion: "(prefers-reduced-motion: reduce)",
      },
      dispatche: false,
    })

    this.call("injectScripts")
    this.addPlayer()
  }

  addPlayer() {
    // Always re-intialize player to avoid issues with turbo cache and shadowRoot.
    this.playerRoot.replaceChildren()

    this.playerEl = document.createElement("div")
    this.playerRoot.appendChild(this.playerEl)

    this.call("player", { el: this.playerEl })
  }

  toggleAnimation(e) {
    const value = get(e, "detail.animated")

    if (typeof value !== "undefined") {
      this.controller.animatedValue = value
    } else {
      this.controller.animatedValue = !this.controller.animatedValue
    }
  }

  reducedMotionChanged({ matches }) {
    this.controller.animatedValue = !matches
  }

  animatedValueChanged() {
    this.addPlayer()
  }

  enhanceController() {
    Object.assign(this.controller, {
      reducedMotionChanged: this.reducedMotionChanged.bind(this),
      animatedValueChanged: this.animatedValueChanged.bind(this),
      toggleAnimation: this.toggleAnimation.bind(this),
    })
  }

  call(methodName, args = {}) {
    const method = get(this.controller, methodName)
    if (typeof method == "function") {
      return method.call(this.controller, args)
    }
  }
}

export const useCapsulePlayer = (controller, options) => {
  new UseCapsulePlayer(controller, options)
}
