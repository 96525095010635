import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="capsule--recorder"
export default class extends Controller {
  static values = {
    btnEl: String,
    slug: String,
    options: Object,
  }

  connect() {
    if (!this.hasSlugValue) {
      return
    }

    this.injectCapsule()
    this.injectRecorderEl()
    this.addRecorder()
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  addRecorder() {
    window.capsuleRecorder({
      slug: this.slugValue,
      el: this.recorderEl,
      buttonEl: this.element,
      ...this.optionsValue,
    })
  }

  injectRecorderEl() {
    if (this.recorderEl) {
      return
    }

    this.recorderEl = document.createElement("div")
    this.recorderEl.setAttribute("data-capsule--recorder", this.slugValue)
    document.body.appendChild(this.recorderEl)
  }

  injectCapsule() {
    if (typeof window.capsuleRecorder === "function") {
      return
    }

    // prettier-ignore
    (function(c,a,p,s,u,l,e,h){c["_capsuleRecorderIdent"]=p;c[p]=c[p]||function(){
      (c[p].q=c[p].q||[]).push(arguments);e=a.querySelector("script[data-capsule='"+p+"']");
      l=a.currentScript?.parentElement||a.body;if(!e){if(typeof Hls=="undefined"){
      h=a.createElement("script");h.src="https://cdn.jsdelivr.net/npm/hls.js@latest";l.appendChild(h);}
      u=a.createElement("script");u.async=1;u.src=s;u.dataset["capsule"]=p;l.appendChild(u);}}})
      (window,document,"capsuleRecorder","https://embed.capsule.video/recorder.js");
  }
}
