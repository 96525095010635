import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-limit"
export default class extends Controller {
  static targets = ["checkbox", "counter"]
  static values = {
    max: Number,
  }

  initialize() {
    this.update = this.update.bind(this)
    this.checkLimit = this.checkLimit.bind(this)
  }

  connect() {
    this.update()
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener("click", this.checkLimit)
      checkbox.addEventListener("change", this.update)
    })
  }

  disconnect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener("click", this.checkLimit)
      checkbox.removeEventListener("change", this.update)
    })
  }

  checkLimit(e) {
    if (this.maxValue && this.checkedCount > this.maxValue) {
      e.preventDefault()
      return false
    }
  }

  update() {
    this.counterTarget.innerHTML = this.checkedCount.toString()
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get checkedCount() {
    return this.checked.length
  }
}
