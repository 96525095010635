import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="listbox"
export default class extends Controller {
  static targets = ["list", "button", "text"]

  connect() {
    // listBox has been deprecated. We need to replace this.
    // see: https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
    this.listBox = new window.aria.Listbox(this.listTarget)
    this.listboxButton = new window.aria.ListboxButton(
      this.buttonTarget,
      this.listBox
    )

    this.handleFocusChange = this.onFocusChange.bind(this)
    this.listBox.setHandleFocusChange(this.handleFocusChange)
  }

  disconnect() {
    delete this.listBox
    delete this.listboxButton
  }

  onFocusChange(element) {
    this.listTarget.querySelectorAll('[role="option"]').forEach(this.unfocus)
    this.textTarget.innerText = element.innerText
    this.focus(element)
  }

  unfocus(element) {
    const active = element.dataset.activeClass.split(" ")
    element.classList.remove(...active)
  }

  focus(element) {
    const active = element.dataset.activeClass.split(" ")
    element.classList.add(...active)
  }
}
