import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="application-action-selector"
export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.submitButton.disabled = true
  }

  handleChange(event) {
    const selectedOption = this.formTarget.querySelector("option:checked")
    const url = selectedOption.dataset.url
    const method = selectedOption.dataset.method
    const value = selectedOption.value

    this.setAction(url)
    this.setMethod(method)
    this.toggleSubmit(value)
  }

  setAction(url) {
    if (this.formTarget) {
      this.formTarget.action = url
    } else {
      console.log("No form target found")
    }
  }

  setMethod(method) {
    if (this.methodInput) {
      this.methodInput.value = method
    } else {
      console.log("No method input found")
    }
  }

  toggleSubmit(value) {
    if (this.submitButton) {
      this.submitButton.disabled = !value
    } else {
      console.log("No submit button found")
    }
  }

  get submitButton() {
    return this.formTarget.querySelector('input[type="submit"]')
  }

  get methodInput() {
    return this.formTarget.querySelector('[name="_method"]')
  }
}
