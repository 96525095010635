import { Controller } from "@hotwired/stimulus"

import {
  secondsFromTime,
  timeFromSeconds,
} from "./time_range_controller.helpers"

/**
 * The Time Range controller handles setting a start/end time range
 * via a range slider and/or directly through input text fields.
 *
 * Example:
 *
 *   <div
 *     class="relative w-full"
 *     data-controller="ui--form--inputs--time-range"
 *     data-ui--form--inputs--time-range-start-value="146"
 *     data-ui--form--inputs--time-range-end-value="900"
 *     data-ui--form--inputs--time-range-duration-value="1000"
 *     data-ui--form--inputs--time-range-gap-value="1"
 *   >
 *     <div>
 *       <div class="relative h-1.5 rounded-md bg-gray-300">
 *         <div data-ui--form--inputs--time-range-target="progress"></div>
 *       </div>
 *       <div class="relative">
 *         <input
 *           data-action="input->ui--form--inputs--time-range#onRangeInput"
 *           data-ui--form--inputs--time-range-target="rangeStart"
 *           step="1"
 *           type="range"
 *         />
 *         <input
 *           data-action="input->ui--form--inputs--time-range#onRangeInput"
 *           data-ui--form--inputs--time-range-target="rangeEnd"
 *           step="1"
 *           type="range"
 *         />
 *       </div>
 *     </div>
 *     <div>
 *       <div class="flex">
 *         <button data-action="ui--form--inputs--time-range#decrementStart" type="button">-</button>
 *         <input
 *           data-action="blur->ui--form--inputs--time-range#onValueInput"
 *           data-ui--form--inputs--time-range-target="inputMin"
 *           type="text" />
 *         <input name="model[starting_at]" type="hidden" value="146" />
 *         <button data-action="ui--form--inputs--time-range#incrementStart" type="button">+</button>
 *       </div>
 *       <div class="flex">
 *         <button data-action="ui--form--inputs--time-range#decrementEnd" type="button">-</button>
 *         <input
 *           data-action="blur->ui--form--inputs--time-range#onValueInput"
 *           data-ui--form--inputs--time-range-target="inputMax"
 *           type="text" />
 *         <input name="model[ending_at]" type="hidden" value="900" />
 *         <button data-action="ui--form--inputs--time-range#incrementEnd" type="button">+</button>
 *       </div>
 *     </div>
 *   </div>
 */
export default class extends Controller {
  static targets = [
    "inputStart",
    "inputEnd",
    "progress",
    "rangeStart",
    "rangeEnd",
  ]

  static values = {
    duration: Number,
    end: Number,
    gap: Number,
    start: Number,
  }

  decrementStart() {
    if (this.startValue > 0) {
      this.startValue -= 1
    }
  }

  decrementEnd() {
    if (this.endValue > this.startValue + this.gapValue) {
      this.endValue -= 1
    }
  }

  incrementStart() {
    if (this.startValue < this.endValue - this.gapValue) {
      this.startValue += 1
    }
  }

  incrementEnd() {
    if (this.endValue < this.durationValue) {
      this.endValue += 1
    }
  }

  onRangeInput = e => {
    const startValue = parseInt(this.rangeStartTarget.value)
    const endValue = parseInt(this.rangeEndTarget.value)

    if (endValue - startValue < this.gapValue) {
      if (e.target == this.rangeStartTarget) {
        // We are dragging the start time handle
        this.rangeStartTarget.value = endValue - this.gapValue
      } else {
        // We are dragging the end time handle
        this.rangeEndTarget.value = startValue + this.gapValue
      }
      this.setProgress()
    } else {
      this.startValue = startValue
      this.inputStartTarget.dataset["ui-Form-Inputs-TimeCurrentValue"] =
        startValue

      this.endValue = endValue
      this.inputEndTarget.dataset["ui-Form-Inputs-TimeCurrentValue"] = endValue
    }
  }

  onEndValueChange = ({ detail: { value } }) => {
    this.endValue = value
  }

  onStartValueChange = ({ detail: { value } }) => {
    this.startValue = value
  }

  redraw = () => {
    this.setFormattedTime()
    this.setProgress()
    this.setInputValues()
  }

  setFormattedTime = () => {
    this.inputStartTarget.value = timeFromSeconds(this.rangeStartTarget.value)
    this.inputEndTarget.value = timeFromSeconds(this.rangeEndTarget.value)
  }

  setInputValues = () => {
    this.inputStartTarget.nextSibling.value = this.startValue
    this.inputEndTarget.nextSibling.value = this.endValue
  }

  setProgress = () => {
    const startValue = parseInt(this.rangeStartTarget.value)
    const endValue = parseInt(this.rangeEndTarget.value)

    this.progressTarget.style.left =
      (startValue / this.rangeStartTarget.max) * 100 + "%"
    this.progressTarget.style.right =
      100 - (endValue / this.rangeEndTarget.max) * 100 + "%"
  }

  /**
   * Value change handlers
   */

  endValueChanged = (value, previousValue) => {
    // Set the max value for the start time input
    this.inputStartTarget.dataset["ui-Form-Inputs-TimeMaxValue"] = value
    this.rangeEndTarget.value = value
    this.redraw()
    this.dispatch("end", { detail: { value, previousValue } })
  }

  startValueChanged = (value, previousValue) => {
    // Set the min value for the end time input
    this.inputEndTarget.dataset["ui-Form-Inputs-TimeMinValue"] = value
    this.rangeStartTarget.value = value
    this.redraw()
    this.dispatch("start", { detail: { value, previousValue } })
  }

  get inputStartInSeconds() {
    return secondsFromTime({
      time: this.inputStartTarget.value,
      max: this.endValue,
      min: 0,
    })
  }

  get inputEndInSeconds() {
    return secondsFromTime({
      time: this.inputEndTarget.value,
      max: this.durationValue,
      min: this.startValue,
    })
  }
}
