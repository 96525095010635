import { Controller } from "@hotwired/stimulus"

// A form validation controller that can do the following:
// * disable the submit button if the input field is empty
// * ensure that a minimum number of checkboxes are checked in a group
// * ensure that a radio button is selected in a group
// * scroll to the first error message if validation fails
// Connects to data-controller="form-validation"
export default class extends Controller {
  static values = { disableSubmit: { Boolean, default: false } }
  static targets = [
    "inputField",
    "checkbox",
    "radio",
    "submitButton",
    "errorMessage",
  ]

  connect() {
    // Disable submit button if disableSubmit value is true
    if (this.disableSubmitValue) {
      this.updateSubmit()
    }

    // Group checkboxes and radio buttons
    this.groups = this.getGroupedElements(this.checkboxTargets)
    this.radioGroups = this.getGroupedElements(this.radioTargets)
  }

  // Group elements based on their data-group attribute
  getGroupedElements(targets) {
    return targets.reduce((groups, element) => {
      const group = element.dataset.group
      if (!groups[group]) {
        groups[group] = []
      }
      groups[group].push(element)
      return groups
    }, {})
  }

  validate(event) {
    let allValid = true
    let firstInvalidElement = null

    const allGroups = this.getAllGroups()
    for (const group of allGroups) {
      const groupElement = this.getGroupElement(group)

      // Skip validation for hidden groups
      if (groupElement.classList.contains("hidden")) {
        continue
      }

      let isValid = true
      if (this.groups[group]) {
        isValid = this.validateCheckboxGroup(group, groupElement)
      } else if (this.radioGroups[group]) {
        isValid = this.validateRadioGroup(group, groupElement)
      }

      if (!isValid) {
        allValid = false
        if (!firstInvalidElement) {
          firstInvalidElement = this.getErrorMessageElement(group)
        }
      }
    }

    if (!allValid) {
      event.preventDefault()
      if (firstInvalidElement) {
        // Scroll to the first error message element
        firstInvalidElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  getAllGroups() {
    const allGroups = [
      ...Object.keys(this.groups),
      ...Object.keys(this.radioGroups),
    ]
    // Sort groups based on their position in the document
    allGroups.sort((a, b) => {
      const aElement = this.getGroupElement(a)
      const bElement = this.getGroupElement(b)
      return aElement.compareDocumentPosition(bElement) &
        Node.DOCUMENT_POSITION_FOLLOWING
        ? -1
        : 1
    })
    return allGroups
  }

  // Get the group element based on its data-group attribute or input name
  getGroupElement(group) {
    return (
      this.element.querySelector(`[data-group="${group}"]`) ||
      this.element.querySelector(`input[name="${group}"]`)
    )
  }

  // Get the error message element for a specific group
  getErrorMessageElement(group) {
    return this.errorMessageTargets.find(
      target => target.dataset.group === group,
    )
  }

  validateCheckboxGroup(group, groupElement) {
    const checkboxes = this.groups[group]
    const checkedBoxes = checkboxes.filter(checkbox => checkbox.checked)
    const minimumCheckboxes = parseInt(
      groupElement.dataset[
        `formValidation${group.charAt(0).toUpperCase() + group.slice(1)}MinimumCheckboxes`
      ],
      10,
    )
    const isValid = checkedBoxes.length >= minimumCheckboxes
    this.updateErrorMessage(group, isValid, minimumCheckboxes, "checkbox")
    return isValid
  }

  validateRadioGroup(group, groupElement) {
    const radios = this.radioGroups[group]
    const selectedRadio = radios.find(radio => radio.checked)
    const isValid = Boolean(selectedRadio)
    this.updateErrorMessage(group, isValid, 1, "radio")
    return isValid
  }

  updateErrorMessage(group, isValid, minimum, type) {
    const errorMessageElement = this.getErrorMessageElement(group)
    if (!isValid) {
      if (type === "checkbox") {
        const checkboxText = minimum > 1 ? "options" : "option"
        errorMessageElement.textContent = `Please select at least ${minimum} ${checkboxText}.`
      } else if (type === "radio") {
        errorMessageElement.textContent = "Please select an option."
      }
    } else {
      errorMessageElement.textContent = ""
    }
  }

  // Disable submit button if input field is empty or no checkbox is selected
  updateSubmit() {
    if (this.hasInputFieldTarget) {
      this.submitButtonTarget.disabled =
        this.inputFieldTarget.value.trim() === ""
    }
    if (this.hasCheckboxTarget) {
      this.submitButtonTarget.disabled = !this.checkboxTargets.some(
        checkbox => checkbox.checked,
      )
    }
  }
}
