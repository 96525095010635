import { Controller } from "@hotwired/stimulus"
import get from "lodash.get"

// Connects to data-controller="toggle-active"
export default class extends Controller {
  static targets = ["output"]
  static values = {
    event: String,
    targetId: String,
  }

  connect() {
    this.setActive(get(this.element, "dataset.active") === "true")
  }

  toggle() {
    this.setActive(!this.active)
  }

  off() {
    this.setActive(false)
  }

  on() {
    this.setActive(true)
  }

  setActive(active) {
    this.active = active
    this.updateOutputs()

    if (this.hasEventValue) {
      this.dispatch(this.eventValue, { detail: { active: this.active } })
    }
  }

  updateOutputs() {
    this.toggleableElements.forEach(element => {
      this.updateClasses(
        element,
        get(element, "dataset.classesActive") ||
          this.defaultOptions.classesActive,
        this.active,
      )
      this.updateClasses(
        element,
        get(element, "dataset.classesInactive") ||
          this.defaultOptions.classesInactive,
        !this.active,
      )
    })
  }

  updateClasses(element, classes, value) {
    if (!classes || !element) {
      return
    }

    element.classList.toggle(classes, value)
  }

  get toggleableElements() {
    if (this.hasOutputTarget) {
      return this.outputTargets
    } else {
      return [document.getElementById(this.targetIdValue)]
    }
  }

  get defaultOptions() {
    return {
      classesActive: "block",
      classesInactive: "hidden",
    }
  }
}
