/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import moment from "moment-timezone/moment-timezone"

window.App.Ui.LocalTime = class LocalTime {
  constructor() {
    this.bindElements()
  }

  bindElements() {
    return $(".local-time").each(function () {
      const $el = $(this)

      const localDate = new Date($el.text())
      return $el.text(moment(localDate).format("MMMM D, YYYY H:mm"))
    })
  }
}

$(document).on("turbo:load", function () {
  if ($(".local-time").length) {
    return new App.Ui.LocalTime()
  }
})
