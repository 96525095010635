export const secondsFromTime = ({ time, max, min }) => {
  let seconds

  const matches = time.match(
    /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/
  )

  if (matches) {
    const hours = parseInt(matches[1], 10) || 0
    const mins = parseInt(matches[2], 10) || 0
    const secs = parseInt(matches[3], 10) || 0
    const totalSeconds = secs + 60 * mins + 3600 * hours

    if (max || min) {
      if (totalSeconds <= max && totalSeconds >= min) {
        seconds = totalSeconds
      } else if (totalSeconds > max) {
        seconds = max
      } else if (totalSeconds < min) {
        seconds = min
      }
    } else {
      seconds = totalSeconds
    }
  } else {
    seconds = 0
  }

  return seconds
}

export const pad = (n, width, z) => {
  z = z || "0"
  n = n + ""
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const timeFromSeconds = seconds => {
  var remainingSeconds = Math.floor(seconds)

  var hrs = Math.floor(seconds / 3600)
  remainingSeconds -= hrs * 3600
  var mins = Math.floor(remainingSeconds / 60)
  remainingSeconds -= mins * 60
  var secs = remainingSeconds

  var labels

  if (hrs > 0) {
    mins = pad(mins, 2)
    secs = pad(secs, 2)
    labels = [hrs, mins, secs]
  } else if (mins > 0) {
    secs = pad(secs, 2)
    labels = [mins, secs]
  } else if (secs > 0) {
    labels = [0, pad(secs, 2)]
  } else {
    labels = [0, "00"]
  }

  return labels.join(":")
}
