import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="focus-element"
export default class extends Controller {
  static targets = ["item"]
  static values = {
    onLoad: false,
  }

  connect() {
    if (this.onLoadValue) {
      this.focus()
    }
    if (!this.itemTarget.hasAttribute("tabindex")) {
      this.itemTarget.tabIndex = 0
    }
  }

  focus() {
    this.itemTarget.focus()
  }
}
