import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlight"
export default class extends Controller {
  static values = {
    word: String, // the word(s) to highlight, like "brain wave"
  }

  connect() {
    if (this.hasWordValue) {
      this.highlightWord()
    }
  }

  disconnect() {
    if (this.hasWordValue) {
      document.removeEventListener(
        "turbo:load",
        this.highlightPattern.bind(this),
      )
    }
  }

  highlightWord() {
    this.element.innerHTML = this.highlightText(
      this.element.innerHTML,
      this.wordValue.split(" ").join("|"),
    )
  }

  highlightText(text, pattern, block) {
    const regex = new RegExp(`(<[^>]*|[^<]+)`, "g")
    const wordRegex = new RegExp(`(${pattern})`, "gi")

    return text
      .match(regex)
      .map(segment => {
        if (segment.startsWith("<")) {
          // Leave HTML tags unmodified
          return segment
        } else {
          // Process only text segments
          return segment.replace(wordRegex, match => {
            return block ? block(match) : `<b class="text-primary">${match}</b>`
          })
        }
      })
      .join("")
  }
}
