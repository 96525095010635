import { Controller } from "@hotwired/stimulus"
import { timeFromSeconds } from "../../ui/form/inputs/time_range_controller.helpers"

/**
 * The Video Crop controller orchestrates communication between a
 * `Ui::YoutubeVideoComponent` and a `Ui::Form::Inputs::TimeRangeComponent`,
 * primarily triggering start/end seeking behavior in the video component
 * when the time range changes in response to user input. It also displays
 * warnings when the time range overlaps with questions in the lesson.
 *
 * Example:
 *
 *   <div
 *     data-controller="lesson-editor--video--crop"
 *     data-action="
 *       ui--form--inputs--time-range:start->lesson-editor--video--crop#startChanged \
 *       ui--form--inputs--time-range:end->lesson-editor--video--crop#endChanged"
 *     data-lesson-editor--video--crop-ui--youtube-video-outlet=".youtube-video"
 *   />
 */
export default class extends Controller {
  static outlets = ["ui--youtube-video"]
  static targets = ["warnings"]
  static values = {
    startAt: Number,
    endAt: Number,
  }

  initialize() {
    this.starting_at = this.startAtValue
    this.ending_at = this.endAtValue
    this.checkForOverlaps()
  }

  startChanged = ({ detail: { value } }) => {
    if (this.videoOutletReady) {
      this.starting_at = value
      this.uiYoutubeVideoOutlet.seek(value)
      this.checkForOverlaps()
    }
  }

  endChanged = ({ detail: { value } }) => {
    if (this.videoOutletReady) {
      this.ending_at = value
      this.uiYoutubeVideoOutlet.seek(value)
      this.checkForOverlaps()
    }
  }

  get videoOutletReady() {
    return this.hasUiYoutubeVideoOutlet && this.uiYoutubeVideoOutlet.readyValue
  }

  get questionElements() {
    return Array.from(this.warningsTarget.querySelectorAll("ul li"))
  }

  get startTimes() {
    return this.warningsTarget.querySelectorAll("span[data-starting-at]")
  }

  get endTimes() {
    return this.warningsTarget.querySelectorAll("span[data-ending-at]")
  }

  hideQuestionElements() {
    this.questionElements.forEach(q => {
      q.classList.add("hidden")
    })
  }

  // check for question hints outside the new time range
  // and display a warning if there are any
  checkForOverlaps() {
    this.hideQuestionElements()

    // get all questions that fall outside the new time range
    let questionsForDisplay = this.questionElements.filter(
      q =>
        q.dataset.duration < this.starting_at ||
        q.dataset.duration > this.ending_at
    )

    this.startTimes.forEach(el => {
      el.innerText = timeFromSeconds(this.starting_at)
    })
    this.endTimes.forEach(el => {
      el.innerText = timeFromSeconds(this.ending_at)
    })

    if (questionsForDisplay.length > 0) {
      questionsForDisplay.forEach(q => {
        q.classList.remove("hidden")
      })
      this.warningsTarget.classList.remove("hidden")
    } else {
      this.warningsTarget.classList.add("hidden")
    }
  }
}
