import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts"
import Accessibility from "highcharts/modules/accessibility"
import { lastDayOfMonth, format, addDays } from "date-fns"

Accessibility(Highcharts)

// Connects to data-controller="chart"
export default class extends Controller {
  static targets = ["output", "total"]
  static values = {
    highchartType: { type: String, default: "sparkline" },
    url: String,
    startDate: Number,
    title: String,
    mode: String,
    yAxisText: String,
    chartType: String,
    link: String,
    filter: String,
    estimatedDataDate: Number,
    data: Array,
    total: Number,
    totalDescription: { type: String, default: "Total" },
  }

  initialize() {}

  connect() {
    switch (this.highchartTypeValue) {
      case "sparkline":
        this.initializeSparklineGraph()
        break
      case "line":
        this.initializeLineGraph()
        break
      case "pie":
        this.initializePieChart()
        break
      default:
        console.log("No chart type specified")
    }
  }

  disconnect() {}

  initializeLineGraph() {
    const chart = this.outputTarget

    this.lineGraph(
      chart.id,
      this.titleValue,
      this.dataValue,
      this.startDateValue,
      this.modeValue,
      this.yAxisTextValue,
    )
  }

  initializeSparklineGraph() {
    const chart = this.outputTarget

    this.fetchData().then(data => {
      let filter, total_html, title_html
      this.sparklineGraph(
        chart.id,
        this.titleValue,
        data.data,
        this.startDateValue,
        this.modeValue,
        this.yAxisTextValue,
        this.estimatedDataDateValue,
      )
      const total = this.addCommas(data.total)

      title_html = '" title="' + this.totalDescriptionValue + '"'
      if (this.hasLinkValue && this.linkValue !== "") {
        total_html =
          '<a href="' + this.linkValue + '"' + title_html + ">" + total + "</a>"
      } else {
        total_html = "<span" + title_html + ">" + total + "</span>"
      }

      if (this.filterValue === undefined) {
        filter = ""
      } else {
        filter = this.filterValue
      }

      this.totalTarget.innerHTML = total_html
    })
  }

  initializePieChart() {
    const chart = this.outputTarget

    this.pieChart(chart.id, this.titleValue, this.dataValue)
  }

  lineGraph(id, title, multi_data, start_date, mode, y_axis_text) {
    let chart
    if (y_axis_text === "undefined") {
      y_axis_text = null
    }

    return (chart = Highcharts.chart(id, {
      chart: {
        type: "line",
        height: 250,
        zoomType: "x",
        spacingRight: 20,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      tooltip: {
        formatter() {
          const dateText = (() => {
            switch (mode) {
              case "day":
                return format(new Date(this.x), "eee, d MMM")
              case "week":
                return (
                  format(new Date(Math.max(this.x, start_date)), "eee, d MMM") +
                  " - " +
                  format(addDays(new Date(this.x), 6), "eee, d MMM")
                )
              case "month":
                const startDateFormatted = format(
                  new Date(Math.max(this.x, start_date)),
                  "eee, d MMM",
                )
                const endDate = lastDayOfMonth(new Date(this.x))
                // Adjust the endDate to the end of the day if necessary
                const endDateFormatted = format(endDate, "eee, d MMM")
                return `${startDateFormatted} - ${endDateFormatted}`
            }
          })()
          return (
            dateText + "<br/>" + this.series.name + ": <b>" + this.y + "</b>"
          )
        },
      },
      legend: {
        borderWidth: 0,
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%b %e",
          week: "%b %e",
        },
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          style: {
            color: "#7d7d7d",
            fontWeight: "normal",
          },
        },
        gridLineWidth: 0,
        lineWidth: 1,
        tickLength: 5,
        minPadding: 0.05,
        showLastLabel: true,
        maxZoom: 14 * 24 * 3600000,
      }, // fourteen days
      yAxis: {
        title: {
          text: y_axis_text,
          margin: 20,
          style: {
            color: "#7d7d7d",
            fontWeight: "normal",
            fontSize: "10px",
          },
        },
        labels: {
          style: {
            color: "#7d7d7d",
            fontWeight: "normal",
          },
        },
        gridLineWidth: 1,
        gridLineColor: "#eee",
        lineWidth: 1,
        tickLength: 5,
        min: 0,
        minTickInterval: 1,
      },
      plotOptions: {
        line: {
          lineWidth: 1,
          shadow: false,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              linewidth: 1,
            },
          },
        },
      },
      series: multi_data,
    }))
  }

  sparklineGraph(id, title, data, start_date, mode, y_axis_text) {
    let chart
    if (y_axis_text === "undefined") {
      y_axis_text = null
    }

    return (chart = Highcharts.chart(id, {
      chart: {
        type: "line",
        height: 140,
      },
      accessibility: {
        enabled: true,
        description: title,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      tooltip: {
        formatter() {
          const dateText = (() => {
            switch (mode) {
              case "day":
                return format(new Date(this.x), "eee, d MMM")
              case "week":
                return (
                  format(new Date(Math.max(this.x, start_date)), "eee, d MMM") +
                  " - " +
                  format(addDays(new Date(this.x), 6), "eee, d MMM")
                )
              case "month":
                const startDateFormatted = format(
                  new Date(Math.max(this.x, start_date)),
                  "eee, d MMM",
                )
                const endDate = lastDayOfMonth(new Date(this.x))
                // Adjust the endDate to the end of the day if necessary
                const endDateFormatted = format(endDate, "eee, d MMM")
                return `${startDateFormatted} - ${endDateFormatted}`
            }
          })()
          return (
            dateText + "<br/>" + this.series.name + ": <b>" + this.y + "</b>"
          )
        },
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%b %e",
          week: "%b %e",
        },
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          style: {
            color: "#7d7d7d",
            fontWeight: "normal",
          },
        },
        lineWidth: 1,
        tickLength: 5,
        minPadding: 0.05,
      },
      yAxis: {
        title: {
          text: y_axis_text,
          margin: 20,
          style: {
            color: "#7d7d7d",
            fontWeight: "normal",
            fontSize: "10px",
          },
        },
        labels: {
          style: {
            color: "#7d7d7d",
            fontWeight: "normal",
          },
        },
        gridLineWidth: 1,
        gridLineColor: "#eee",
        lineWidth: 1,
        tickLength: 5,
        min: 0,
      },
      plotOptions: {
        line: {
          lineWidth: 1,
          shadow: false,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              linewidth: 1,
            },
          },
        },
      },
      series: [
        {
          name: title,
          data: data,
        },
      ],
      legend: {
        enabled: false,
      },

      colors: ["#0077cc"],
    }))
  }

  pieChart(id, title, data, units, legend) {
    let chart
    if (units === undefined) {
      units = "times"
    }
    if (legend === undefined) {
      legend = false
    }
    return (chart = Highcharts.chart(id, {
      chart: {
        height: 250,
        spacingBottom: 5,
        spacingTop: 5,
        spacingLeft: 5,
        spacingRight: 5,
      },
      colors: [
        "#1F1F5E",
        "#D08514",
        "#13517D",
        "#D08514",
        "#778EC6",
        "#5BB0F9",
        "#AE242A",
        "#B3B3B3",
        "#AFD515",
        "#098176",
        "#47DFBB",
        "#8148F3",
      ],
      credits: {
        enabled: false,
      },
      title: {
        y: 15,
        text: title,
        floating: true,
        style: {
          color: "#333333",
          fontSize: "12px",
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
      },
      tooltip: {
        enabled: false,
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        formatter() {
          return (
            "<b>" +
            this.point.name +
            "</b>: " +
            this.y +
            " " +
            units +
            " (" +
            Highcharts.numberFormat(this.percentage, 1) +
            "%)"
          )
        },
      },
      plotOptions: {
        pie: {
          size: "47%",
          dataLabels: {
            enabled: true,
            color: "#333333",
            connectorColor: "#666666",
            distance: 20,
            useHTML: true,
            softConnector: false,
            x: 0,
            y: -9,
            format:
              '<div class="label-percentage">{point.percentage:.2f}%<div><div class="label-name">{point.name}</div>',
          },
          allowPointSelect: true,
          showInLegend: legend,
          cursor: "pointer",
          startAngle: 45,
        },
      },
      series: [
        {
          type: "pie",
          name: title,
          data,
        },
      ],
    }))
  }

  async fetchData() {
    if (!this.urlValue) return

    const response = await fetch(this.urlValue)
    return response.json()
  }
  addCommas(nStr) {
    nStr += ""
    const x = nStr.split(".")
    let x1 = x[0]
    let x2 = x.length > 1 ? "." + x[1] : ""
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2")
    }
    return x1 + x2
  }
}
