import { Controller } from "@hotwired/stimulus"

import {
  secondsFromTime,
  timeFromSeconds,
} from "./time_range_controller.helpers"

export default class extends Controller {
  static targets = ["input"]

  static values = {
    current: Number,
    min: { type: Number, default: 0 },
    max: { type: Number, default: Infinity },
  }

  connect() {
    this.setCurrentValueFromCandidate(this.currentValue)
  }

  get inputValueInSeconds() {
    return secondsFromTime({
      time: this.inputTarget.value,
      max: this.maxValue,
      min: this.minValue,
    })
  }

  currentValueChanged = (value, previousValue) => {
    this.setFormattedTime()
    this.setHiddenInputValue()
    this.dispatch("change", { detail: { value, previousValue } })
  }

  decrement() {
    if (this.currentValue > this.minValue) {
      this.currentValue -= 1
    }
  }

  increment() {
    if (this.currentValue < this.maxValue) {
      this.currentValue += 1
    }
  }

  onValueInput = () => {
    let valueInSeconds = this.inputValueInSeconds

    if (valueInSeconds === undefined) return

    this.setCurrentValueFromCandidate(valueInSeconds)
  }

  setFormattedTime = () => {
    this.inputTarget.value = timeFromSeconds(this.currentValue)
  }

  setHiddenInputValue = () => {
    this.inputTarget.nextSibling.value = this.currentValue
  }

  setCurrentValueFromCandidate = candidateValue => {
    if (candidateValue === undefined) return

    let value = candidateValue

    /**
     * If the value is greater than the max, set it to the max.
     */
    if (candidateValue > this.maxValue) {
      value = this.maxValue
    }

    /**
     * If the value is less than the min, set it to the min.
     */
    if (candidateValue < this.minValue) {
      value = this.minValue
    }

    this.currentValue = value

    /**
     * If the new value is the same as the current value, the change callback
     * won't get called, and the input's value not reformatted. We need
     * to manually call for a reformat, so that if an invalid time is input
     * it will still be reformatted and displayed with a time value.
     */
    this.setFormattedTime()
  }
}
