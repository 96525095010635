import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lesson-editor--video--hint"
export default class extends Controller {
  static outlets = [
    "ui--form--inputs--time",
    "ui--form--inputs--range",
    "ui--youtube-video",
  ]

  connect() {
    console.log('Connected to "lesson-editor--video--hint" controller!')
    this.isReady = false
    /** We need a small amount of time for the outlets to connect */
    this.readyTimeout = setTimeout(() => {
      this.isReady = true
    }, 250)
  }

  disconnect() {
    clearTimeout(this.readyTimeout)
  }

  onInputChange({ detail: { value } }) {
    if (!this.isReady) return

    this.uiFormInputsRangeOutlet.currentValue = value

    if (this.videoOutletReady) {
      this.uiYoutubeVideoOutlet.seek(value)
    }
  }

  onRangeChange({ detail: { value } }) {
    if (!this.isReady) return

    this.uiFormInputsTimeOutlet.currentValue = value

    if (this.videoOutletReady) {
      this.uiYoutubeVideoOutlet.seek(value)
    }
  }

  get videoOutletReady() {
    return this.hasUiYoutubeVideoOutlet && this.uiYoutubeVideoOutlet.readyValue
  }
}
