import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="popup-window"
export default class extends Controller {
  static values = {
    width: { type: Number, default: 600 },
    height: { type: Number, default: 400 },
  }

  open(e) {
    if (!this.element.getAttribute("href")) {
      return
    }

    e.preventDefault()

    const features = Object.entries({
      toolbar: "no",
      location: "no",
      directories: "no",
      status: "no",
      menubar: "no",
      scrollbars: "no",
      resizable: "no",
      copyhistory: "no",
      width: this.widthValue,
      height: this.heightValue,
      top: screen.height / 2 - this.heightValue / 2,
      left: screen.width / 2 - this.widthValue / 2,
    })
      .map(([key, value]) => `${key}=${value}`)
      .join(", ")

    window.open(this.element.getAttribute("href"), "", features)
  }
}
