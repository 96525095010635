import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-counter"
// Counter shows characters remaining before reaching max
export default class extends Controller {
  static targets = ["input", "counter"]
  static values = {
    max: Number
  }


  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.update()
    this.inputTarget.addEventListener("input", this.update)
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update)
  }

  update() {
    this.counterTarget.innerHTML = (this.maxValue - this.count).toString()
  }

  get count() {
    return this.inputTarget.value.length
  }
}
