import { Controller } from "@hotwired/stimulus"

const FRAME_CAP = 1000 / 120 // Frame cap
const numberFormat = new Intl.NumberFormat("en-US")

// Connects to data-controller="count-up"
export default class extends Controller {
  static values = {
    duration: { type: Number, default: 1200 },
    to: { type: Number, default: 0 },
    from: { type: Number, default: 0 },
  }

  connect() {
    this.start = null
    this.lastFrame = 0

    window.requestAnimationFrame(this.step.bind(this))
  }

  formatNumber(number) {
    return numberFormat.format(number)
  }

  step(timestamp) {
    if (!this.start) {
      this.start = timestamp
    }
    const elapsed = timestamp - this.start
    const progress = elapsed / this.durationValue

    if (timestamp - this.lastFrame >= FRAME_CAP) {
      const number = Math.floor(
        Math.max(this.fromValue, this.toValue * progress),
      )
      this.element.textContent = this.formatNumber(number)
      this.lastFrame = timestamp
    }

    if (elapsed < this.durationValue) {
      window.requestAnimationFrame(this.step.bind(this))
    } else {
      this.element.textContent = this.formatNumber(this.toValue)
    }
  }
}
