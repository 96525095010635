import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="media-selector-playback"
export default class extends Controller {
  static targets = ["playIcon", "pauseIcon"]

  static values = {
    selector: {
      type: String,
      default: "video",
    },
    isPlaying: {
      type: Boolean,
      default: true,
    },
  }

  toggle() {
    this.isPlayingValue = !this.isPlayingValue
  }

  isPlayingValueChanged() {
    this.setIcons()
    this.setMediaTargets()
  }

  setMediaTargets() {
    if (!this.mediaTargets.length) {
      return
    }

    for (const media of this.mediaTargets) {
      media.autoplay = this.isPlayingValue
      if (this.isPlayingValue) {
        media.play()
      } else {
        media.pause()
      }
    }
  }

  setIcons() {
    this.playIconTarget.classList.toggle("invisible", this.isPlayingValue)
    this.pauseIconTarget.classList.toggle("invisible", !this.isPlayingValue)
  }

  get mediaTargets() {
    return this.element.querySelectorAll(this.selectorValue)
  }
}
