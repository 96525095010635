import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lesson-editor--questions"
export default class extends Controller {
  static targets = ["correctInput"]

  /**
   * When an answer is marked as "correct", all other answers should
   * be unchecked, as we should only have one correct answer per question.
   */
  markCorrectAnswer(event) {
    if (this.hasCorrectInputTarget) {
      this.correctInputTargets
        .filter(el => el !== event.target)
        .forEach(input => {
          input.checked = false
        })
    }
  }
}
