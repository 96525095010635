import { Controller } from "@hotwired/stimulus"
import { useResize, useIntersection } from "stimulus-use"

// Connects to data-controller="lessons--page--layout"
export default class extends Controller {
  static targets = [
    "video",
    "panel",
    "sidebar",
    "navLink",
    "lessonComplete",
    "lessonViewed",
  ]

  static values = {
    width: { type: Number, default: 640 },
  }

  initialize() {
    this.onResize = this.resized.bind(this)
    this.onAppear = this.visible.bind(this)

    this.boundScrollIntoView = this.scrollIntoView.bind(this)
  }

  connect() {
    this.element.addEventListener("turbo:frame-load", this.boundScrollIntoView)

    this.element.addEventListener("sidebar:resize", this.onResize)
    this.element.addEventListener("sidebar:appear", this.onAppear)

    this.element.addEventListener("panel:resize", this.onResize)
    this.element.addEventListener("panel:appear", this.onAppear)
  }

  scrollIntoView(event) {
    // Do not use custom scroll position on 'lg:' or higher sizes
    if (window.matchMedia("(min-width: 1200px)").matches) {
      return
    }

    const src = event.target?.getAttribute("src")
    const [, , action] = new URL(src).pathname
      .split("/")
      .filter(segment => segment !== "")

    if (!action) {
      return
    }

    if (!src || action === "watch") {
      event.target?.scrollIntoView({ block: "start" })
    } else {
      this.navigation?.scrollIntoView({ block: "start" })
    }
  }

  sidebarTargetConnected(element) {
    useResize(this, { element, eventPrefix: "sidebar" })
    useIntersection(this, { element, eventPrefix: "sidebar" })
  }

  panelTargetConnected(element) {
    useResize(this, { element, eventPrefix: "panel" })
    useIntersection(this, { element, eventPrefix: "panel" })
  }

  resized(event) {
    if (this.active === event.target) {
      this.animateVideoDock(event.target)
    }
  }

  visible(event) {
    this.active = event.target
    this.animateVideoDock(event.target)
  }

  animateVideoDock(targetEl) {
    const parent = this.element.getBoundingClientRect()
    const { top, left, width, height } = targetEl.getBoundingClientRect()

    if (!width || !height) {
      return
    }

    const scale = width / this.widthValue

    this.videoTarget.style = [
      `--tw-translate-x:${left - parent.left}px;`,
      `--tw-translate-y:${top - parent.top}px;`,
      `--tw-scale-x:${scale};`,
      `--tw-scale-y:${scale};`,
    ].join("")
  }

  markViewed() {
    if (this.hasLessonViewedTarget) {
      this.lessonViewedTarget.click()
    }
  }

  completeWatch() {
    const watchIsActive =
      this.navLinkTargets[0]?.getAttribute("aria-current") === "true"

    if (this.hasLessonCompleteTarget) {
      this.lessonCompleteTarget.click()
    }

    if (watchIsActive && !!this.navLinkTargets[1]) {
      this.navLinkTargets[1].click()
    }
  }

  get navigation() {
    return this.element.querySelector(
      '[data-lessons--page--layout-target="navLink"]',
    )
  }
}
