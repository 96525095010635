import { Controller } from "@hotwired/stimulus"

// This controller is used to store values in a cookie.
// Add the cookies controller to an outer element, with a
// data-cookies-cookie-name attribute.
// Then add a data-cookies-target="link" to the element that should react to
// an event (with data-action="cookies#storeValue"), and supply a
// data-cookies-store-value-param attribute with the value you want to store.
// Only the first maxItems values are stored in the cookie.
export default class extends Controller {
  static values = {
    maxItems: { type: Number, default: 3 },
    cookieName: String,
    days: { type: Number, default: 3 },
  }
  static targets = ["link"]

  connect() {
    console.debug(
      "Cookies Controller Connected with cookieName:",
      this.cookieNameValue,
    )
    // this.resetCookie()
  }

  storeValue(event) {
    const value = event.currentTarget.dataset.cookiesStoreValueParam

    let storedValues = (this.getCookie(this.cookieNameValue) || "").split(",")
    storedValues = storedValues.filter(v => v)

    if (!storedValues.includes(value)) {
      storedValues.push(value)
    }
    storedValues = storedValues.slice(0, this.maxItemsValue)

    this.setCookie(this.cookieNameValue, storedValues.join(","))

    if (event.currentTarget.dataset.redirectUrl) {
      event.preventDefault()
      window.location.href = event.currentTarget.dataset.redirectUrl
    }
  }

  getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
  }

  setCookie(name, value) {
    let expires = ""
    if (this.hasDaysValue) {
      const date = new Date()
      date.setTime(date.getTime() + this.daysValue * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`
  }

  resetCookie() {
    this.setCookie(this.cookieNameValue, "", -1)
  }
}
