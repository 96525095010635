import { Controller } from "@hotwired/stimulus"
import { useInject } from "../../../javascript/controllers/mixins/useInject"

/**
 * Adds external dependencies to the DOM if they don't exist.
 * This allows for component level dependency inclusion without
 * duplicating DOM elements or weighing down every site page.
 */

// Connects to data-controller="embeds--calendly--popup"
export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    useInject(this)

    this.injectScript("https://assets.calendly.com/assets/external/widget.js")
    this.injectStyle("https://assets.calendly.com/assets/external/widget.css")
  }

  open() {
    if (
      typeof window.Calendly.initPopupWidget !== "function" ||
      !this.urlValue
    ) {
      return
    }

    window.Calendly.initPopupWidget({ url: this.urlValue })
  }
}
