import { Controller } from "@hotwired/stimulus"

// Borrowed from https://github.com/stimulus-components/stimulus-scroll-to
// Connects to data-controller="scroll-to"
// Apply this to a link and it will automatically scroll to the supplied anchor.
// Or apply this to some other element, for example a button, and provide
// the id of the element to scroll to with
// data-scroll-to-element-id-value="my-element-id"
export default class extends Controller {
  static values = {
    offset: Number,
    behavior: String,
    elementId: String,
  }

  initialize() {
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    this.element.addEventListener("click", this.scroll)
  }

  disconnect() {
    this.element.removeEventListener("click", this.scroll)
  }

  scroll(event) {
    let targetElement

    if (this.hasElementIdValue) {
      // Directly scroll to element if ID is provided
      targetElement = document.getElementById(this.elementIdValue)
      if (!targetElement) {
        console.warn(
          `[stimulus-scroll-to] The element with the id: "${this.elementIdValue}" does not exist on the page.`,
        )
        return
      }

      targetElement.scrollIntoView(this.behavior)
    } else {
      // If no direct ID is provided, use the hash from the element
      // (for anchor links)
      event.preventDefault()

      const elementIdFromHash = this.element.hash.replace(/^#/, "")
      targetElement = document.getElementById(elementIdFromHash)
      if (!targetElement) {
        console.warn(
          `[stimulus-scroll-to] The element with the id: "${elementIdFromHash}" does not exist on the page.`,
        )
        return
      }

      // Calculate position with offset
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.scrollY - this.offset

      window.scrollTo({
        top: targetPosition,
        behavior: this.behavior,
      })
    }
  }

  get offset() {
    return this.hasOffsetValue
      ? this.offsetValue
      : this.defaultOptions.offset !== void 0
        ? this.defaultOptions.offset
        : 10
  }

  get behavior() {
    return this.behaviorValue || this.defaultOptions.behavior || "smooth"
  }

  get defaultOptions() {
    return {}
  }
}
