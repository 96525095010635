import { Controller } from "@hotwired/stimulus"

/**
 * The Video Button controller orchestrates communication between a
 * `Ui::YoutubeVideoComponent` and a button to control the video.
 *
 * Example:
 *
 *   <button
 *     data-controller="ui--youtube-video-button"
 *     data-action="ui--youtube-video-button#play"
 *     data-ui--youtube-video-button-seek-param="90"
 *     data-ui--youtube-video-button-ui--youtube-video-outlet=".youtube-video"
 *   />
 */
export default class extends Controller {
  static outlets = ["ui--youtube-video"]

  play(event) {
    if (!this.videoOutletReady) {
      return
    }

    if (event.params?.seek) {
      this.uiYoutubeVideoOutlet.seek(event.params?.seek)
    }

    this.uiYoutubeVideoOutlet.play()
  }

  get videoOutletReady() {
    return this.hasUiYoutubeVideoOutlet && this.uiYoutubeVideoOutlet.readyValue
  }
}
