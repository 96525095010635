import { Controller } from "@hotwired/stimulus"
import { useMatchMedia } from "stimulus-use"

// Connects to data-controller="ui--navigation--mobile"
export default class extends Controller {
  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        md: "(min-width: 768px)",
      },
    })
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  toggle({ detail: { active } }) {
    if (active) {
      this.open()
    } else {
      this.close()
    }
  }

  isMd() {
    this.close()
  }

  open() {
    this.lockScroll()
    this.element.classList.remove("hidden")
  }

  close() {
    this.unlockScroll()
    this.element.classList.add("hidden")
  }

  // Credit to:
  // https://github.com/excid3/tailwindcss-stimulus-components/blob/04a853d085204e95c57a67814bf0a58bb7eeb02a/src/modal.js#L145
  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  // Credit to:
  // https://github.com/excid3/tailwindcss-stimulus-components/blob/04a853d085204e95c57a67814bf0a58bb7eeb02a/src/modal.js#L149
  restoreScrollPosition() {
    if (this.scrollPosition !== 0 && !this.scrollPosition) return

    this.timeout = setTimeout(() => window.scrollTo(0, this.scrollPosition), 5)
  }

  // Credit to:
  // https://github.com/excid3/tailwindcss-stimulus-components/blob/04a853d085204e95c57a67814bf0a58bb7eeb02a/src/modal.js#L115
  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden")
  }

  // Credit to:
  // https://github.com/excid3/tailwindcss-stimulus-components/blob/04a853d085204e95c57a67814bf0a58bb7eeb02a/src/modal.js#131
  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden")

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()
  }
}
