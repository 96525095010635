import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

// Connects to data-controller="lazy-load"
export default class extends Controller {
  connect() {
    useIntersection(this)
  }

  appear(entry, observer) {
    if (this.element.tagName === "VIDEO") {
      this.loadVideo(entry, observer)
    }
  }

  loadVideo(entry, observer) {
    entry.target.querySelectorAll("source").forEach(source => {
      source.src = source.dataset.src
    })
    entry.target.load()
    observer.unobserve(entry.target)
  }
}
