// Lesson filter selection/selected behaviour
window.App.Components.TededFilter = class TededFilter {
  constructor() {
    this.$body = $("body")
    this.activeSelector = "[aria-checked='true']"
    this.activeClass = "tdd-filter--checked"

    this.injectAriaAttributes()
    // To Do: keyboard accessibility
    this.setupInitalState()
    this.bindElements()
  }

  destroy() {
    this.$body.off("click.tededfilter.option")
  }

  bindElements() {
    // Bind filter option to update on click
    this.$body.on("click.tededfilter.option", "[data-filter-opt]", e => {
      let $target = $(e.target)
      if (!$target.attr("data-filter-opt")) {
        $target = $target.closest("[data-filter-opt]")
      }

      if ($target) {
        return this.toggleActive($target)
      }
    })

    // Bind reset option to clear any related filters
    return this.$body.on(
      "click.tededfilter.clear",
      "[data-filter-clear]",
      e => {
        e.preventDefault()
        const name = $(e.currentTarget).data("filter-clear")
        return $(`[data-filter-list='${name}'] [data-filter-opt]`).each(
          (i, el) => {
            return this.toggleActive($(el), false)
          },
        )
      },
    )
  }

  setupInitalState() {
    // Add active class to any pre-selected options
    document.querySelectorAll("[data-filter-opt]").forEach(function (element) {
      if (!element.querySelector("span[data-filter-label]")) {
        var span = document.createElement("span")
        span.setAttribute("data-filter-label", "")
        while (element.firstChild) {
          span.appendChild(element.firstChild)
        }
        element.appendChild(span)
      }
    })

    $("[data-filter-opt]" + this.activeSelector)
      .find("[data-filter-label]")
      .addClass(this.activeClass)

    // Update comboboxes with any pre-selected data
    $("[data-filter-combobox]").each((i, el) => {
      return this.updateCombobox($(el).data("filter-combobox"))
    })

    // Update list counters with any pre-selected data
    return $("[data-filter-list]").each((i, el) => {
      return this.updateCounter($(el).data("filter-list"))
    })
  }

  // Add aria role attirubtes to elements.
  injectAriaAttributes() {
    $("[data-filter-list]").attr("role", "listbox")
    $("[data-filter-opt]").attr("role", "menuitemcheckbox")
    return $("[data-filter-opt]").attr("aria-checked", function (i, val) {
      if (val === "true") {
        return "true"
      } else {
        return "false"
      }
    })
  }

  // Format the combobox text to return the text of the first two results and
  // then the count of additional fitlers, or the default text.
  formatComboboxText(first, second, ...rest) {
    if (first != null && $(first).text() != null) {
      first = $(first).text().trim()
    }
    if (second != null && $(second).text() != null) {
      second = $(second).text().trim()
    }

    if (first && second && rest.length > 0) {
      return `${first}, ${second}, (+${rest.length})`
    } else if (first && second) {
      return `${first}, ${second}`
    } else if (first) {
      return first
    }
  }

  // Update the combobox text.
  updateCombobox(name) {
    const selected = $(`[data-filter-opt='${name}']` + this.activeSelector)
    const filterList = $(`[data-filter-combobox='${name}']`)
    let content = filterList.data("empty")
    if (selected.length > 0) {
      const long = $("<span />")
        .addClass("tdd-filter__label-long")
        .text(this.formatComboboxText(...Array.from(selected || [])))
      const short = $("<span />")
        .addClass("tdd-filter__label-short")
        .text(selected.length + " added")
      if (selected.length < 2 && $(selected).data("short-name") != null) {
        short.text($(selected).data("short-name"))
      }
      content = $("<span />").append(short).append(long)
    }
    return filterList.html(content)
  }

  // Update the counter value to the number of selected children.
  updateCounter(name) {
    const selected = $(
      `[data-filter-list='${name}'] [data-filter-opt]` + this.activeSelector,
    ).length
    return $(`[data-filter-counter='${name}']`).text(selected)
  }

  // Toggle the selected state of a filter option
  toggleActive($option, checked) {
    if (checked == null) {
      checked = "toggle"
    }
    if (checked === "toggle") {
      checked = $option.attr("aria-checked") === "true" ? "false" : "true"
    }
    const name = $option.data("filter-opt")

    if (
      checked === "true" &&
      $(`[data-filter-combobox='${name}']`).data("filter-mode") === "single"
    ) {
      $(`[data-filter-opt='${name}']`).attr("aria-checked", "false")
      $(`[data-filter-opt='${name}'] [data-filter-label]`).removeClass(
        this.activeClass,
      )
    }

    $option.attr("aria-checked", checked)
    const label = $option.find("[data-filter-label]")
    if (checked === "true") {
      label.addClass(this.activeClass)
    } else {
      label.removeClass(this.activeClass)
    }
    return this.optionUpdated($option)
  }

  optionUpdated($option) {
    $option.parents("[data-filter-list]").each((i, e) => {
      return this.updateCounter($(e).data("filter-list"))
    })
    return this.updateCombobox($option.data("filter-opt"))
  }
}

// Avoid double-loading on turbo page loads
let filterInstance = null

function initializeFilter() {
  if ($("[data-filter-opt]").length > 0 && !filterInstance) {
    filterInstance = new App.Components.TededFilter()
  }
}

function destroyFilter() {
  if (filterInstance) {
    filterInstance.destroy()
    filterInstance = null
  }
}

$(document).on("turbo:load", function () {
  destroyFilter()
  initializeFilter()
})

$(document).on("turbo:before-cache", function () {
  destroyFilter()
})
