import { Controller } from "@hotwired/stimulus"

import Swiper from "swiper/swiper-bundle"
import "./carousel_component"

// Webpack needs actual CSS extensions specified.
import "swiper/swiper-bundle.css"
import "swiper/modules/effect-fade.css"

const arrowsHiddenClasses = ["invisible", "pointer-events-none"]

// Connects to data-controller="sliders--carousel"
export default class extends Controller {
  static outlets = ["sliders--carousel"]

  static targets = [
    "pagination",
    "nextButton",
    "prevButton",
    "slide",
    "swiper",
    "container",
  ]

  static values = {
    options: { type: Object, default: {} },
  }

  connect() {
    this.addSlides()

    this.swiper = new Swiper(this.swiperTarget, {
      ...this.optionsValue,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        ...this.optionsValue.pagination,
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
        navigationDisabledClass: "swiper-navigation-disabled",
        disabledClass: "!hidden",
        hiddenClass: "!hidden",
        ...this.optionsValue.navigation,
      },
    })

    this.slidersCarouselOutlets.forEach(outlet => {
      this.swiper.controller.control = outlet.swiper
    })
  }

  addSlides() {
    if (this.hasSlideTarget) {
      this.slideTargets.forEach(slide => {
        slide.classList.add("swiper-slide")
      })
    } else {
      for (let child of this.containerTarget.children) {
        child.classList.add("swiper-slide")
      }
    }
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }
}
