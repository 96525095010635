import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

// TODO: Improve accessibility based on:
// https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-autocomplete-list/

// Connects to data-controller="combobox"
export default class extends Controller {
  static targets = ["input", "list", "option", "button"]

  connect() {
    useClickOutside(this)

    this.boundOnFocus = this.onFocus.bind(this)
    this.boundFilter = this.filter.bind(this)
    this.boundOnKeyDown = this.onKeyDown.bind(this)

    this.element.addEventListener("focusin", this.boundOnFocus)

    this.inputTarget.addEventListener("input", this.boundFilter)
    this.inputTarget.addEventListener("keydown", this.boundOnKeyDown)

    this.optionTargets.forEach(option => {
      option.addEventListener("keydown", this.boundOnKeyDown)
    })

    this.keyDownEvents = {
      Down: e => this.nextOption(e),
      ArrowDown: e => this.nextOption(e),
      Up: e => this.prevOption(e),
      ArrowUp: e => this.prevOption(e),
      Escape: e => this.close(e),
      Esc: e => this.close(e),
      Home: e => this.jumpStart(e),
      End: e => this.jumpEnd(e),
    }

    this.selected = null
  }

  clickOutside() {
    this.close()
  }

  filter() {
    const searchText = this.inputTarget.value.toLowerCase()
    this.optionTargets.forEach(option => {
      const optionText = option.textContent.toLowerCase()
      option.hidden = optionText.indexOf(searchText) < 0
    })
  }

  onFocus(event) {
    const target = event?.srcElement
    if (this.element.contains(target)) {
      this.open()
    }
  }

  toggle() {
    if (this.listTarget.getAttribute("aria-expanded") === "true") {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.listTarget.classList.remove("invisible")
    this.listTarget.setAttribute("aria-expanded", "true")
    this.buttonTarget.setAttribute("aria-expanded", "true")
  }

  close(event) {
    if (this.listTarget.contains(event?.target)) {
      this.inputTarget.focus()
      return
    }

    this.listTarget.classList.add("invisible")
    this.listTarget.setAttribute("aria-expanded", "false")
    this.buttonTarget.setAttribute("aria-expanded", "false")

    this.inputTarget.blur()
  }

  onKeyDown(event) {
    this.keyDownEvents[event.key]?.(event)
  }

  nextOption(event) {
    event.preventDefault()
    this.setSelected(this.visibleOptions[this.nextIndex])
  }

  prevOption(event) {
    event.preventDefault()
    this.setSelected(this.visibleOptions[this.prevIndex])
  }

  setSelected(option) {
    this.selected = option
    this.inputTarget.setAttribute("aria-activedescendant", option.id)
    option.scrollIntoView({ behavior: "smooth", block: "nearest" })
    option.setAttribute("aria-selected", "true")

    if (option.dataset?.focus) {
      option.querySelector(option.dataset.focus).focus()
    }
  }

  clearSelected() {
    this.selected = null
    this.inputTarget.setAttribute("aria-activedescendant", "")
  }

  jumpEnd() {
    const length = this.inputTarget.value.length
    this.inputTarget.setSelectionRange(length, length)
  }

  jumpStart() {
    this.inputTarget.setSelectionRange(0, 0)
  }

  get visibleOptions() {
    return this.optionTargets.filter(option => !option.hidden)
  }

  get selectedIndex() {
    return !this.selected ? -1 : [...this.visibleOptions].indexOf(this.selected)
  }

  get nextIndex() {
    if (
      this.selectedIndex < 0 ||
      this.selectedIndex === this.visibleOptions.length - 1
    ) {
      return 0
    }

    return this.selectedIndex + 1
  }

  get prevIndex() {
    if (this.selectedIndex < 1) {
      return this.visibleOptions.length - 1
    }

    return this.selectedIndex - 1
  }
}
