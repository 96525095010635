import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-form"
export default class extends Controller {
  static targets = ["form"]
  static values = {
    remote: { type: Boolean, default: false }
  }

  submit() {
    if (this.remoteValue) {
      // Remote form submission. Will not load new page.
      return this.formTarget.requestSubmit()
    } else {
      // Normal form submission. Will load new page.
      this.formTarget.submit()
    }
  }
}
