import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-load"
export default class extends Controller {
  static values = { video: String }
  static targets = ["card"]

  connect() {
    const urlParams = new URLSearchParams(window.location.search)
    const videoQuery = urlParams.get("video")
    if (videoQuery == this.videoValue) {
      $(this.cardTarget).trigger("click")
    }
  }
}
