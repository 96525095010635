import { Controller } from "@hotwired/stimulus"
import { useMatchMedia } from "stimulus-use"

// Connects to data-controller="ui--toggle-animations"
export default class extends Controller {
  static values = {
    animated: { type: Boolean, default: true },
  }

  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        reducedMotion: "(prefers-reduced-motion: reduce)",
      },
      dispatchEvent: false,
    })
  }

  toggle() {
    this.animatedValue = !this.animatedValue
  }

  on() {
    this.animatedValue = true
  }

  off() {
    this.animatedValue = false
  }

  reducedMotionChanged({ matches }) {
    this.animatedValue = !matches
  }

  animatedValueChanged() {
    this.dispatch("change", {
      detail: { animated: this.animatedValue },
    })
  }
}
