import { Controller } from "@hotwired/stimulus"

/**
 * The Sortable Numbers controller provides a way to update a numeric
 * label after a sortable list is reordered.
 *
 * Example:
 *
 *   <div
 *     data-controller="sortable sortable-numbers"
 *     data-action="sortable:end->sortable-numbers#update"
 *   >
 *     <div data-sortable-numbers-target="number">A</div>
 *     <div data-sortable-numbers-target="number">B</div>
 *     <div data-sortable-numbers-target="number">C</div>
 *   </div>
 */
export default class extends Controller {
  static targets = ["item", "number"]

  itemTargetDisconnected(element) {
    this.dispatch("removed", { detail: { element } })
  }

  update() {
    if (this.hasNumberTarget) {
      let i = 1
      for (const number of this.numberTargets) {
        // Only adjust when number is visible
        if (number.offsetParent !== null) {
          number.innerText = i
          i++
        }
      }
    }
  }
}
