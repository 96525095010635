import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users--additional-information-form"
export default class extends Controller {
  static targets = ["interestsLabel", "studentLevels"]

  static values = {
    roles: Object,
    userRole: Number,
  }

  updateUserRole(e) {
    const roleName = this.rolesValue?.[parseInt(e.target.value)]
    if (roleName) {
      this.toggleStudentLevels(roleName === "educator")
      this.updateInterestsLabel(roleName)
    }
  }

  updateInterestsLabel(roleName) {
    const labels = JSON.parse(this.interestsLabelTarget.dataset.labels)
    if (labels[roleName]) {
      this.updateInterestsLabelText(labels[roleName])
    } else if (labels["default"]) {
      this.updateInterestsLabelText(labels["default"])
    }
  }

  updateInterestsLabelText(text) {
    this.interestsLabelTarget.childNodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        node.textContent = text
      }
    })
  }

  toggleStudentLevels(enabled = true) {
    this.studentLevelsTarget.classList.toggle("hidden", !enabled)
    this.studentLevelsTarget
      .querySelector("fieldset")
      .classList.toggle("disabled", !enabled)
    this.studentLevelsTarget.querySelectorAll("input").forEach(field => {
      field.disabled = !enabled
    })
  }
}
