import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="ui--announcement-banner"
export default class extends Controller {
  static values = {
    name: String,
  }

  connect() {
    if (this.hasViewed) {
      this.element.remove()
    } else {
      enter(this.element)
    }
  }

  dismiss() {
    leave(this.element).then(() => {
      localStorage.setItem(this.nameValue, Date.now())
      this.element.remove()
    })
  }

  get hasViewed() {
    return !!localStorage.getItem(this.nameValue)
  }
}
