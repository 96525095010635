import { Controller } from "@hotwired/stimulus"

// Toggles text on a button based on the selected radio button
// Connects to data-controller="text-toggle"
export default class extends Controller {
  static targets = ["button"]
  static values = { optionName: String }

  updateButtonText() {
    if (this.hasOptionNameValue) {
      const selectedOption = this.element.querySelector(
        `input[name="${this.optionNameValue}"]:checked`,
      )
      const newText = selectedOption.dataset.textToggleTargetText

      this.buttonTarget.textContent = newText
    }
  }
}
