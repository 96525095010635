import { Controller } from "@hotwired/stimulus"

// This loads the search results and opens the modal if users go directly to
// to a search results page where the video was opened.
// Connects to data-controller="video-search"
export default class extends Controller {
  static values = { search: String }
  static targets = ["query"]

  connect() {
    if (this.hasSearchValue) {
      this.loadResults()
    }
  }

  loadResults() {
    this.queryTarget.value = this.searchValue
    var form = $(this.queryTarget).closest("form").get(0)
    form.requestSubmit()
  }
}