import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = {
    source: String,
  }

  copy(event) {
    event.preventDefault()

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.sourceValue)
        .then(() => this.displayConfirmation(event))
        .catch(err => this.logError(err))
    } else {
      let textarea = document.createElement("textarea")
      textarea.value = this.sourceValue
      event.target.parentElement.appendChild(textarea)
      textarea.focus()
      textarea.select()

      try {
        document.execCommand("copy")
        this.displayConfirmation(event)
      } catch (err) {
        this.logError(err)
      }

      event.target.parentElement.removeChild(textarea)
    }
  }

  displayConfirmation(event) {
    let originalText = event.target.innerText
    // If we have existing text, then replace with a brief confirmation message
    if (originalText) {
      originalText = originalText.trim()
      const confirmationText = "Copied!"
      this.updateTextContent(event.target, confirmationText)
      event.target.classList.add("text-green-700")

      setTimeout(() => {
        this.updateTextContent(event.target, originalText)
        event.target.classList.remove("text-green-700")
      }, 800)
    }

    this.dispatch("copied", { detail: { source: this.sourceValue } })
  }

  // Utility method to update text without affecting child elements
  updateTextContent(element, newText) {
    let textNode = [...element.childNodes].find(
      node => node.nodeType === Node.TEXT_NODE,
    )
    if (textNode) {
      textNode.nodeValue = newText
    } else {
      element.appendChild(document.createTextNode(newText))
    }
  }

  logError(err) {
    console.error("Failed to copy: ", err)
  }
}
