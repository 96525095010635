import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-click"
export default class extends Controller {
  static targets = ["clickable"]

  connect() {
    if (this.hasClickableTarget) {
      this.clickableTarget.click()
    }
  }
}
