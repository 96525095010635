// Reloads lessons page content after filter selections
window.App.Components.TededLessonsGrid = class TededLessonsGrid {
  constructor() {
    let filters
    this.getFilters = this.getFilters.bind(this)
    this.updateCategoryLabel = this.updateCategoryLabel.bind(this)
    this.updateFilters = this.updateFilters.bind(this)
    this.reloadContent = this.reloadContent.bind(this)
    this.$body = $("body")
    this.$lessonsGrid = $("#lessons-grid")
    this.filters = filters = [
      "subtitles",
      "content_type",
      "student_level",
      "duration",
    ]
    this.activeClass = "tdd-dropdown--active"
    this.spinner = $("<img />").attr("src", this.$lessonsGrid.data("spinner"))
    this.updateCategoryLabel()
    this.bindElements()
  }

  destroy() {
    this.$body.off("click.lessonsgrid")
  }

  bindElements() {
    this.$body.on("click.lessonsgrid", "[data-category]", e => {
      e.preventDefault()
      const $selected = $(e.currentTarget)
      const $parentMenu = $selected.closest('[data-dropdown="menu"]')
      $("[data-category]").attr("aria-selected", "false")
      $selected.attr("aria-selected", "true")
      this.updateActiveClasses($parentMenu, '[aria-selected="true"]')
      this.updateCategoryLabel()
      return this.updateFilters(true)
    })

    this.$body.on("click.lessonsgrid", "[data-lesson-filter]", e => {
      e.preventDefault()
      return this.updateFilters(true)
    })

    this.$body.on(
      "click.lessonsgrid",
      "[data-sort-by], [data-category], [data-lesson-value], [data-filter-clear]",
      e => {
        return $('#lessons-filters [data-dropdown="menu"]')
          .toArray()
          .forEach(menu => {
            return this.dispatchEvent("tededdropdown.close", {
              menuId: "#" + $(menu).attr("id"),
            })
          })
      },
    )

    return this.$body.on(
      "click.lessonsgrid",
      "#lessons-filters [data-dropdown-anchor]",
      e => {
        return $("#lessons-filters [data-dropdown-anchor]")
          .not($(e.currentTarget))
          .find('[data-dropdown="menu"]')
          .toArray()
          .forEach(menu => {
            return this.dispatchEvent("tededdropdown.close", {
              menuId: "#" + $(menu).attr("id"),
            })
          })
      },
    )
  }

  buildFilterString(filtername) {
    return $(`[data-lesson-filter='${filtername}'][aria-checked='true']`)
      .map(function () {
        return $(this).data("lesson-value")
      })
      .get()
      .join("+")
  }

  getFilters() {
    return this.filters.reduce((result, filterName) => {
      const filterString = this.buildFilterString(filterName)
      if (!!filterString) {
        result[filterName] = filterString
      }
      return result
    }, {})
  }

  updateCategoryLabel() {
    const $category = $('[data-category][aria-selected="true"]').first().text()
    const defaultText = $("[data-category-label]").data("category-label")
    return $("[data-category-label]").text($category || defaultText)
  }

  updateFilters() {
    const params = this.getFilters()
    let catTitle = ""
    const $category = $('[data-category][aria-selected="true"]').first()
    const $sort = $('[data-filter-opt="sortby"][aria-checked="true"]').first()

    if ($category.length > 0) {
      catTitle = $category.text()
      params["category"] = $category.data("category")
    }

    if ($sort.length > 0) {
      params["direction"] = $sort.data("sort-direction")
      params["sort"] = $sort.data("sort-by")
    }

    const lessons_url = `/lessons?${decodeURIComponent($.param(params))}`
    return this.reloadContent(lessons_url, catTitle)
  }

  reloadContent(lessons_url, pageTitle) {
    const $click_blocker = $("<div />").addClass("fixed inset-0")
    const $loading_message = $("<div />")
      .text(" Loading lessons...")
      .prepend(this.spinner)
      .append("<br/><br/>")

    this.$body.append($click_blocker)
    this.$lessonsGrid.before($loading_message)
    $("[data-lesson-grid-content]").hide()

    fetch(lessons_url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.text()
      })
      .then(turboStream => {
        // Create a temporary element to hold the Turbo Stream response
        const tempDiv = document.createElement("div")
        tempDiv.innerHTML = turboStream

        // Append the Turbo Stream elements to the body
        document.body.appendChild(tempDiv)

        // Remove the loading indicators
        $click_blocker.remove()
        console.log("Loading content complete")
        $loading_message.remove()
      })
      .catch(error => {
        console.error("Error loading content:", error)
      })

    if (pageTitle != null) {
      document.title = `${pageTitle} Lessons | TED-Ed`
    }

    return history.pushState({ module: "lessons" }, document.title, lessons_url)
  }

  updateActiveClasses($menu, selector) {
    const { activeClass } = this
    $menu.find("." + this.activeClass).removeClass(activeClass)
    $menu.find(selector).addClass(activeClass)
    return $menu
      .find("[aria-hidden]")
      .has(selector)
      .each(function () {
        const id = "#" + $(this).attr("id")
        return $(`[aria-haspopup][href='${id}']`).addClass(activeClass)
      })
  }

  dispatchEvent(eventName, params) {
    const closeMenu = document.createEvent("CustomEvent")
    closeMenu.initCustomEvent(eventName, false, false, params)
    return document.dispatchEvent(closeMenu)
  }
}

// Avoid double-loading on turbo page loads
let gridInstance = null

function initializeGrid() {
  if ($('[data-dropdown="menu"]').length > 0 && !gridInstance) {
    gridInstance = new App.Components.TededLessonsGrid()
  }
}

function destroyGrid() {
  if (gridInstance) {
    gridInstance.destroy()
    gridInstance = null
  }
}

$(document).on("turbo:load", function () {
  destroyGrid()
  initializeGrid()
})

$(document).on("turbo:before-cache", function () {
  destroyGrid()
})
