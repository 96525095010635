import { Controller } from "@hotwired/stimulus"
import { useCapsulePlayer } from "./useCapsulePlayer"
import { useModifyShadowRoot } from "./useModifyShadowRoot"

// Connects to data-controller="capsule--video-wall"
export default class extends Controller {
  static targets = ["output"]
  static values = {
    slug: String,
    options: { type: Object, default: {} },
    animated: { type: Boolean, default: true },
  }

  connect() {
    if (!this.hasSlugValue) {
      return
    }

    this.onShadowRootChange = this.modifyShadowRoot.bind(this)

    useCapsulePlayer(this)
    useModifyShadowRoot(this, this.onShadowRootChange)
  }

  player({ el }) {
    window.capsuleDotVideo({
      ...this.options,
      el,
      slug: this.slugValue,
      animated: this.animatedValue,
    })
  }

  modifyShadowRoot(entries) {
    const capsuleDotVideo = [...entries].find(({ target }) =>
      target.querySelector("capsule-player")
    )

    if (capsuleDotVideo) {
      const capsulePlayer =
        capsuleDotVideo.target.querySelector("capsule-player").shadowRoot
      capsulePlayer.querySelector(".overlay").style.zIndex = 9999
    }
  }

  get options() {
    return {
      cols: 4,
      mobileCols: 2,
      cta: false,
      metaDisplay: false,
      ...this.optionsValue,
    }
  }

  injectScripts() {
    if (typeof window.capsuleDotVideo === "function") {
      return
    }

    // prettier-ignore
    (function(c,a,p,s,u,l,e){c["_capsuleDotVideoIdent"]=p;c[p]=c[p]||function(){
      (c[p].q=c[p].q||[]).push(arguments);e=a.querySelector("script[data-capsule='"+p+"']");
      if(!e){u=a.createElement("script");u.async=1;u.src=s;u.dataset["capsule"]=p;
      l=a.currentScript?.parentElement||a.body;l.appendChild(u);}}})
      (window,document,"capsuleDotVideo","https://embed.capsule.video/grid.js");
  }
}
