import { Controller } from "@hotwired/stimulus"
import get from "lodash.get"

// Connects to data-controller="field-toggle"
export default class extends Controller {
  static targets = ["input"]

  static values = {
    mustMatch: Object,
    matchAttr: String,
  }

  connect() {
    this.toggleElements()
  }

  toggleElements() {
    const toggles = [...this.toggleTargets]
    toggles.forEach(toggle => {
      this.toggleElement(toggle)
    })
  }

  toggleElement(el) {
    const hidden = !this.isMatch(el.id)
    el.classList.toggle("hidden", hidden)
    this.toggleInputFields(el, hidden)
  }

  toggleInputFields(el, disabled) {
    const inputs = el.querySelectorAll("input, textarea, select")
    inputs.forEach(input => {
      input.disabled = disabled
    })
  }

  isMatch(id) {
    const matches = get(this.mustMatchValue, id)
    const values = Array.isArray(matches) ? matches : []
    return typeof this.currentValue !== "undefined"
      ? values.includes(this.currentValue)
      : false
  }

  get currentValue() {
    const name = this.element.getAttribute("name")
    const selected = document.querySelector(`input[name="${name}"]:checked`)

    if (!selected) {
      return undefined
    }

    const value = this.matchAttrValue
      ? get(selected, `dataset.${this.matchAttrValue}`)
      : get(selected, "value")

    return value
  }

  get toggleTargets() {
    const fieldIds = Object.keys(this.mustMatchValue)
      .map(id => `#${id}`)
      .join(", ")

    return document.querySelectorAll(fieldIds)
  }
}
