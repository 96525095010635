import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="push-state"
export default class extends Controller {
  static values = { url: String }

  update(e) {
    history.pushState(null, null, this.urlValue)
  }

  back(e) {
    history.back()
  }
}
