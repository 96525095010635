import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ui--form--searchbox"
export default class extends Controller {
  static get targets() {
    return ["input", "resetBtn", "submitBtn"]
  }

  connect() {
    this.update()
  }

  update() {
    this.toggleBtns({ isEmpty: !this.inputTarget.value })
  }

  resetForm() {
    this.inputTarget.value = ""
    this.toggleBtns({ isEmpty: true })
  }

  toggleBtns({ isEmpty }) {
    this.resetBtnTarget.classList.toggle("invisible", isEmpty)

    this.submitBtnTarget.classList.toggle("text-gray-700", isEmpty)
    this.submitBtnTarget.classList.toggle("bg-transparent", isEmpty)
    this.submitBtnTarget.classList.toggle("text-white", !isEmpty)
    this.submitBtnTarget.classList.toggle("bg-gray-700", !isEmpty)
    this.submitBtnTarget.disabled = isEmpty
  }
}
