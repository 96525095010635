import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="administrative-areas"
export default class extends Controller {
  static values = { initialCode: String }
  static targets = ["selectField"]

  connect() {
    this.updateRequired(this.initialCodeValue)
  }

  update(e) {
    if (typeof e.target.value !== "string" || !e.target.value.length) {
      return
    }

    const basePath = "/student_talks/organizations/update_administrative_areas"
    const targetId = this.selectFieldTarget.id
    const code = e.target.value

    this.updateRequired(code)

    get(`${basePath}?country_code=${code}&target_id=${targetId}`, {
      responseKind: "turbo-stream",
    })
  }

  updateRequired(code) {
    if (code === "US") {
      this.makeRequired()
    } else {
      this.makeOptional()
    }
  }

  makeRequired() {
    this.selectFieldTarget.setAttribute("aria-required", "true")
    this.selectFieldTarget.required = true
    this.selectFieldTarget.classList.add("required")

    this.requiredMark.classList.remove("hidden")
  }

  makeOptional() {
    this.selectFieldTarget.setAttribute("aria-required", "false")
    this.selectFieldTarget.required = false
    this.selectFieldTarget.classList.remove("required")

    this.requiredMark.classList.add("hidden")
  }

  get requiredMark() {
    const id = this.selectFieldTarget.id
    return document.querySelector(`label[for=${id}] .text-required`)
  }
}
