import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown"
export default class extends Controller {
  static values = {
    start: Number,
    globalEvent: String,
  }

  static targets = ["output"]

  connect() {
    this.interval = null

    this.countdown()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  countdown() {
    this.update(this.startValue)

    this.interval = setInterval(() => {
      const newValue = this.startValue - 1
      this.update(newValue)
    }, 1000)
  }

  update(value) {
    this.startValue = value

    const minutes = Math.floor((value % 3600) / 60)
    const seconds = value % 60

    if (minutes > 1) {
      this.outputTarget.textContent = `${minutes} minutes`
    } else if (minutes === 1) {
      this.outputTarget.textContent = `${minutes} minute`
    } else {
      this.outputTarget.textContent = `${seconds} seconds`
    }

    if (value <= 0) {
      clearInterval(this.interval)
      this.dispatch("finished")
      this.dispatchGlobalEvent()
    }
  }

  dispatchGlobalEvent() {
    if (!this.globalEventValue) {
      return
    }

    document.dispatchEvent(
      new CustomEvent(this.globalEventValue, { bubbles: true })
    )
  }
}
