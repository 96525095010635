import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="dependent-select"
export default class extends Controller {
  static targets = ["source", "target"]
  static values = {
    allowBlank: { type: Boolean, default: false },
    sourceRoutePart: String,
    targetRoutePart: String,
    displayAttribute: String,
  }

  update() {
    const sourceValue = this.sourceTarget.value
    if (sourceValue) {
      this.populateSelect(sourceValue)
    } else {
      this.clearSelect()
    }
  }

  async populateSelect(sourceId, targetId = null) {
    const url = `/${this.sourceRoutePartValue}/${sourceId}/${this.targetRoutePartValue}`
    const response = await get(url, { responseKind: "json" })

    if (response.ok) {
      const data = await response.json
      this.updateTargetOptions(data, targetId)
    }
  }

  updateTargetOptions(data, targetId) {
    const selectBox = this.targetTarget
    selectBox.innerHTML = ""

    if (this.allowBlankValue) {
      const blankOption = document.createElement("option")
      blankOption.value = ""
      blankOption.innerHTML = ""
      selectBox.appendChild(blankOption)
    }

    data.forEach(item => {
      const option = document.createElement("option")
      option.value = item.id
      option.innerHTML = item[this.displayAttributeValue]
      option.selected = parseInt(targetId) === item.id
      selectBox.appendChild(option)
    })
  }

  clearSelect() {
    const selectBox = this.targetTarget
    selectBox.innerHTML = ""
  }
}
