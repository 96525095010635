import { Controller } from "@hotwired/stimulus"

/**
 * The Sortable Positions controller provides a way to update a nested
 * form element's `position` property after the list is reordered.
 *
 * Example:
 *
 *   <div
 *     data-controller="sortable sortable-positions"
 *     data-action="sortable:end->sortable-positions#update"
 *   >
 *     <input type="hidden" data-sortable-positions-target="input" />
 *     <input type="hidden" data-sortable-positions-target="input" />
 *     <input type="hidden" data-sortable-positions-target="input" />
 *   </div>
 */
export default class extends Controller {
  static targets = ["input"]

  update() {
    if (this.hasInputTarget) {
      let i = 1
      for (const input of this.inputTargets) {
        input.value = i
        i++
      }
    }
  }
}
