export const useInject = controller => {
  Object.assign(controller, {
    injectScript(src) {
      if (!!document.querySelector(`script[src="${src}"]`)) {
        return
      }
      const tag = document.createElement("script")
      tag.type = "text/javascript"
      tag.async = true
      tag.src = src
      document.body.appendChild(tag)
    },
    injectStyle(href) {
      if (!!document.querySelector(`link[href="${href}"]`)) {
        return
      }

      const tag = document.createElement("link")
      tag.rel = "stylesheet"
      tag.href = href
      document.head.appendChild(tag)
    },
  })
}
