import { Controller } from "@hotwired/stimulus"
import { useIdle } from "stimulus-use"
import { get, post } from "@rails/request.js"

const heartbeatInterval = 30 * 1000 // 30 seconds
const idleTimeout = 8 * 60 * 1000 // 8 minutes

// Connects to data-controller="lessons--toolbars--temp-user"
export default class extends Controller {
  connect() {
    useIdle(this, {
      ms: idleTimeout,
      dispatchEvents: false,
      events: [
        "mousemove",
        "mousedown",
        "resize",
        "keydown",
        "touchstart",
        "wheel",
        "ui--youtube-video:timer",
      ],
    })

    this.heartbeat = null

    this.keepAlive()

    this.checkTempUserAllowed()
    this.boundOnRouteChange = this.onRouteChange.bind(this)
    document.addEventListener("turbo:load", this.boundOnRouteChange)
  }

  onRouteChange() {
    this.checkTempUserAllowed()
  }

  // Stimulus-use idle callback
  away() {
    this.warn()
  }

  disconnect() {
    this.endKeepAlive()

    Turbo.cache.clear()

    document.removeEventListener("turbo:load", this.boundOnRouteChange)
  }

  keepAlive() {
    this.heartbeat = setInterval(() => {
      this.refreshSession()
    }, heartbeatInterval)
  }

  endKeepAlive() {
    clearInterval(this.heartbeat)
  }

  warn() {
    // The session warning is reponsible for terminating the session.
    get("/temp_users/session_warning", {
      responseKind: "turbo-stream",
    })
  }

  // Check for the presence of the think section of a lesson.
  // If not present, we assume the user is outside a lesson and end the session.
  checkTempUserAllowed() {
    if (document.querySelectorAll('[id^="panel_think_lesson_"]').length < 1) {
      this.endSession()
    } else {
      this.refreshSession()
    }
  }

  endSession() {
    this.endKeepAlive()

    // Clear the Turbo cache to prevent back-button issues
    Turbo.cache.clear()

    post("/temp_users/end_session", { responseKind: "turbo-stream" })

    // Reload the think turbo_frame to clear temp user content.
    document.querySelector('[id^="panel_think_lesson_"]')?.reload?.()
  }

  // We use a server heartbeat so that closed browsers might also terminate
  // the session.
  async refreshSession() {
    const response = await get("/temp_users/active", {
      contentType: "application/json",
    })
    if (response.ok) {
      const data = await response.json
      if (data.live === false) {
        this.endSession()
      }
    } else {
      console.error("There was an error checking the session:", error)
    }
  }
}
