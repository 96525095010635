/**
 * @todo Add character counter (this is in the old editor, but also see
 * https://quilljs.com/guides/building-a-custom-module/)
 */
import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import Quill from "quill"

export default class extends Controller {
  static targets = [
    "editor",
    "file",
    "input",
    "preview",
    "previewButton",
    "previewNote",
    "toolbar",
    "uploading",
  ]

  static values = {
    options: Object,
    preview: Boolean,
  }

  initialize() {
    this.quill = new Quill(this.editorTarget, this.options)
    this.addEventListeners()

    this.quill.getModule("toolbar").addHandler("image", () => {
      this.selectLocalImage()
    })
  }

  connect() {
    this.addEventListeners()
  }

  disconnect() {
    this.removeEventListeners()
  }

  addEventListeners() {
    this.quill.on("text-change", this.onTextChange)
  }

  /**
   * Dispatch an event so other controllers can act upon the change.
   *
   * In the following example, a "character-counter" element is updated
   * when the quill editor content changes.
   *
   * Example:
   *   <div
   *     data-controller="quill-editor character-counter"
   *     data-action="quill-editor:change->character-counter#update"
   *   >
   *     <input
   *       type="hidden"
   *       data-quill-editor-target="input"
   *       data-character-counter-target="input"
   *     />
   *     <span data-character-counter-target="counter"></span>
   *   </div>
   */
  dispatchChangeEvent({ source }) {
    if (source !== "user") return

    this.dispatch("change", {
      detail: { content: this.quill.getText() },
    })
  }

  insertToEditor = url => {
    const range = this.quill.getSelection()
    this.quill.insertEmbed(range.index, "image", url)
  }

  /**
   * Check if there is a `max_length` option present, and if so remove
   * any text after the length index. Otherwise, set the input value.
   */
  limitTextLength = (_delta, _oldContents, _source) => {
    if (!this.hasInputTarget) return

    if (
      "max_length" in this.options &&
      this.quill.getLength() > this.options.max_length
    ) {
      this.quill.deleteText(this.options.max_length, this.quill.getLength())
    }

    this.inputTarget.value = this.quill.container.firstChild.innerHTML
  }

  onTextChange = (delta, oldContents, source) => {
    this.limitTextLength(delta, oldContents, source)
    this.dispatchChangeEvent({ source: source })
  }

  previewValueChanged() {
    if (!this.hasPreviewTarget) return

    const editorContent = this.quill.root.innerHTML
    this.previewTarget.innerHTML = editorContent

    if (this.previewValue) {
      this.editorTarget.style.display = "none"
      this.previewTarget.style.display = "block"
      this.previewButtonTarget.innerHTML = "Edit"
      this.previewButtonTarget.title = "Edit"
      this.previewNoteTarget.style.display = "block"
      document
        .querySelector("div[data-character-counter-wrapper]")
        .classList.add("hidden")
    } else {
      this.editorTarget.style.display = "flex"
      this.previewTarget.style.display = "none"
      this.previewButtonTarget.innerHTML = "Preview"
      this.previewButtonTarget.title = "Preview"
      this.previewNoteTarget.style.display = "none"
      document
        .querySelector("div[data-character-counter-wrapper]")
        .classList.remove("hidden")
    }
  }

  removeEventListeners() {
    this.quill.off("text-change", this.onTextChange)
  }

  saveToServer = async file => {
    const fd = new FormData()
    fd.append("file", file)
    let image_upload_url = "/redactor_images"
    this.uploadingTarget.classList.remove("hidden")

    const response = await post(image_upload_url, {
      body: fd,
    })

    if (response.ok) {
      const data = await response.text
      const url = JSON.parse(data).url

      this.uploadingTarget.classList.add("hidden")
      this.insertToEditor(url)
    } else {
      console.log("A problem happened")
    }
  }

  selectLocalImage = () => {
    this.fileTarget.setAttribute("type", "file")
    this.fileTarget.setAttribute("accept", "image/*")
    this.fileTarget.addEventListener("change", async () => {
      let file = this.fileTarget.files[0]
      if (/^image\//.test(file.type)) {
        await this.saveToServer(file)
      } else {
        console.warn("You may only upload images.")
      }
    })
    this.fileTarget.value = ""
    this.fileTarget.click()
  }

  togglePreview() {
    this.previewValue = !this.previewValue
  }

  get defaultOptions() {
    return {
      theme: "snow",
      formats: ["bold", "underline", "italic", "link", "image", "script"],
      modules: {
        toolbar: this.toolbarTarget,
      },
    }
  }

  get options() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue,
    }
  }
}
