import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

// Connects to data-controller="lessons--card-actions"
export default class extends Controller {
  static get targets() {
    return ["menu", "btn", "listener"]
  }

  initialize() {
    // this.previous = false
  }

  connect() {
    useClickOutside(this)
  }

  clickOutside() {
    this.toggleMenu(false)
  }

  close() {
    this.toggleMenu(false)
  }

  toggle() {
    const expanded = this.btnTarget.getAttribute("aria-expanded") === "true"
    this.toggleMenu(!expanded)
  }

  toggleMenu(expanded) {
    if (expanded !== this.previous) {
      this.menuTarget.classList.toggle("hidden", !expanded)
      this.btnTarget.setAttribute("aria-expanded", expanded ? "true" : "false")

      this.dispatchEvent(`menu-${expanded ? "open" : "close"}`)
    }

    this.previous = expanded
  }

  dispatchEvent(eventName) {
    this.listenerTargets.forEach(target => {
      this.dispatch(eventName, { target, bubbles: false })
    })
  }
}
