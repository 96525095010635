import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="media-playback"
export default class extends Controller {
  static targets = ["media", "playIcon", "pauseIcon"]

  connect() {
    this.mediaTarget.addEventListener("ended", this.onPlaybackChange)
    this.mediaTarget.addEventListener("pause", this.onPlaybackChange)
    this.mediaTarget.addEventListener("play", this.onPlaybackChange)
  }

  disconnect() {
    this.mediaTarget.addEventListener("pause", this.onPlaybackChange)
    this.mediaTarget.removeEventListener("ended", this.onPlaybackChange)
    this.mediaTarget.removeEventListener("play", this.onPlaybackChange)
  }

  get isPlaying() {
    return !!this.mediaTarget.paused === false
  }

  onPlaybackChange = _event => {
    this.setIcons()
  }

  pause() {
    this.mediaTarget.pause()
  }

  play() {
    this.mediaTarget.play()
  }

  setIcons() {
    this.playIconTarget.classList.toggle("invisible", this.isPlaying)
    this.pauseIconTarget.classList.toggle("invisible", !this.isPlaying)
  }

  toggle() {
    if (this.isPlaying) {
      this.pause()
    } else {
      this.play()
    }
  }
}
