import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="notifications--item"
export default class extends Controller {
  static targets = ["markAsRead"]

  connect() {
    this.onClick = this.markAsRead.bind(this)

    this.element.addEventListener("click", this.onClick)
  }

  async markAsRead() {
    if (!this.hasMarkAsReadTarget) {
      return
    }

    const request = new FetchRequest(
      this.markAsReadTarget.dataset.method || "get",
      this.markAsReadTarget.href,
      { responseKind: "turbo-stream" },
    )
    const response = await request.perform()

    if (response.ok) {
      this.markAsReadTarget.remove()
      this.element.classList.remove("unread")
    }
  }
}
