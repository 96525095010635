import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["wrapper", "body", "closeButton"]
  static values = {
    closeOnEsc: { type: Boolean, default: true },
    closeOnSubmit: { type: Boolean, default: true },
    autoClose: { type: Boolean, default: false },
  }

  connect() {
    if (!this.autoCloseValue) {
      document.addEventListener("keydown", this.handleKeyDown)

      if (this.closeOnSubmitValue) {
        document.addEventListener("turbo:submit-end", this.handleSubmit)
      }
      this.lastFocusedElement = null
      this.open()
    } else {
      this.autoClose()
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyDown)

    if (this.closeOnSubmitValue) {
      document.removeEventListener("turbo:submit-end", this.handleSubmit)
    }
  }

  handleKeyDown = e => {
    if (this.closeOnEscValue && e.key === "Escape") {
      e.stopPropagation()
      this.close()
    }
  }

  open() {
    this.lastFocusedElement = document.activeElement
    this.bodyTarget.setAttribute("aria-hidden", "false")
    enter(this.wrapperTarget)
    enter(this.bodyTarget)
    document.body.classList.add("overflow-hidden")
    this.closeButtonTarget.focus()
    this.trapFocus(this.bodyTarget)
    this.dispatch("open")
  }

  close(e) {
    leave(this.wrapperTarget)
    leave(this.bodyTarget).then(() => {
      this.bodyTarget.setAttribute("aria-hidden", "true")

      // Dispatch a close event before removing modal, in case something in the
      // modal needs to respond to it
      this.dispatch("close")

      // Remove the modal element after the fade out so it doesn't blanket the
      // screen
      this.element.remove()
    })

    document.body.classList.remove("overflow-hidden")

    // Return focus to the last focused element
    if (this.lastFocusedElement) this.lastFocusedElement.focus()

    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent clicks
    this.element.closest("turbo-frame").src = undefined
  }

  autoClose(e) {
    this.element.remove()
    document.body.classList.remove("overflow-hidden")
  }

  handleSubmit = e => {
    if (e.detail.success) {
      this.close()
    }
  }

  trapFocus(element) {
    const focusableElements = element.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    )
    const firstFocusableElement = focusableElements[0]
    const lastFocusableElement = focusableElements[focusableElements.length - 1]

    element.addEventListener("keydown", function (event) {
      if (event.key === "Tab") {
        if (event.shiftKey) {
          /* shift + tab */ if (
            document.activeElement === firstFocusableElement
          ) {
            event.preventDefault()
            lastFocusableElement.focus()
          }
        } /* tab */ else {
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault()
            firstFocusableElement.focus()
          }
        }
      }
    })
  }
}
