export class UseModifyShadowRoot {
  constructor(controller, modifyShadowRoot) {
    if (typeof modifyShadowRoot !== "function") {
      return
    }

    this.modifyAttachShadow()

    this.controller = controller
    this.observer = new MutationObserver(modifyShadowRoot)
    this.onAttachShadow = this.observeShadowRoot.bind(this)
    this.controllerDisconnect = this.controller.disconnect.bind(this.controller)

    this.onConnect()
    this.enhanceController()
  }

  onConnect() {
    document.addEventListener("attachShadow", this.onAttachShadow)
  }

  onDisconnect() {
    document.removeEventListener("attachShadow", this.onAttachShadow)
    this.observer.disconnect()
    this.controllerDisconnect()
  }

  modifyAttachShadow() {
    const original = Element.prototype.attachShadow
    Element.prototype.attachShadow = function attachShadow(...args) {
      const shadowRoot = original.apply(this, args)
      this.dispatchEvent(
        new CustomEvent("attachShadow", {
          bubbles: true,
          composed: true,
          detail: { shadowRoot },
        })
      )
      return shadowRoot
    }
  }

  observeShadowRoot(event) {
    const shadowRoot = event.detail?.shadowRoot
    if (shadowRoot) {
      this.observer.observe(shadowRoot, { subtree: true, childList: true })
    }
  }

  enhanceController() {
    Object.assign(this.controller, {
      disconnect: this.onDisconnect.bind(this),
    })
  }
}

export const useModifyShadowRoot = (controller, options) => {
  new UseModifyShadowRoot(controller, options)
}
