import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

// Connects to data-controller="details"
export default class extends Controller {
  connect() {
    // Ensure the element is a <details> tag
    if (!(this.element instanceof HTMLDetailsElement)) {
      console.warn(
        `The details controller can only be used with <details> elements, but was used with ${this.element.tagName}.`,
      )
      return
    }

    useClickOutside(this, { events: ["click", "touchend", "focus"] })
  }

  clickOutside() {
    this.close()
  }

  close() {
    this.element.open = false
  }

  open() {
    this.element.open = true
  }

  focusSummary() {
    this.summary?.focus()
  }

  get summary() {
    return this.element.querySelector("summary")
  }
}
