import { Controller } from "@hotwired/stimulus"
import YouTubePlayer from "youtube-player"

/**
 * Display and control YouTube videos.
 *
 * Initial code borrowed from @leastbad's stimulus-youtube controller.
 * https://gist.github.com/leastbad/33a97dc724d7bf21b7f6ff1992e5aab9
 *
 * Utilizes the "youtube-player" iframe API abstraction:
 * https://github.com/gajus/youtube-player
 *
 * Example:
 *
 *   <div
 *     class="youtube-video relative h-0 aspect-w-16 aspect-h-9"
 *     data-controller="ui--youtube-video"
 *     data-ui--youtube-video-code-value="sp3B97N67Cw"
 *     data-ui--youtube-video-width-value="640"
 *     data-ui--youtube-video-height-value="360"
 *   >
 *     <button data-action="ui--youtube-video#play">Play</button>
 *     <button data-action="ui--youtube-video#pause">Pause</button>
 *     <button data-action="ui--youtube-video#stop">Stop</button>
 *     <br />
 *     <div data-ui--youtube-video-target="frame"></div>
 *   </div>
 */
export default class extends Controller {
  static targets = ["frame"]

  static values = {
    code: String,
    duration: Number,
    endingAt: Number,
    height: { type: Number, default: 360 },
    ready: { type: Boolean, default: false },
    startingAt: Number,
    state: Number,
    time: Number,
    width: { type: Number, default: 640 },
  }

  initialize() {
    this.element["youtube"] = this
  }

  connect() {
    if (!this.hasCodeValue) {
      console.log("No YouTube code value was provided.")
      return
    }

    const player = YouTubePlayer(this.frameTarget, {
      height: this.heightValue,
      playerVars: {
        start: this.startingAtValue,
        end: this.endingAtValue,
      },
      videoId: this.codeValue,
      width: this.widthValue,
    })

    player.on("ready", this.onPlayerReady.bind(this))
    player.on("stateChange", this.onPlayerStateChange.bind(this))
    player.on("playbackRateChange", this.onPlaybackRateChange.bind(this))

    this.addFullscreenListeners()
  }

  disconnect() {
    this.player.destroy()
    this.removeFullscreenListeners()
  }

  onPlayerReady(event) {
    this.durationValue = event.target.getDuration()
    this.youtube = event.target
    this.timeValue = this.time
    this.stateValue = -1
    this.readyValue = true
    this.fiftyPercentRaised = false
    this.seventyFivePercentRaised = false
  }

  onPlayerStateChange(event) {
    this.stateValue = event.data
    this.timeValue = this.time

    this.dispatch(this.stateNames[event.data])
    event.data === 1 ? this.startTimer() : this.stopTimer()
    this.dispatch(this.stateNames[event.data], {
      detail: { event, time: this.time },
    })
  }

  onPlaybackRateChange(event) {
    this.dispatch("playbackRateChange", {
      detail: { playbackRate: event.data },
    })
  }

  addFullscreenListeners() {
    const fullscreenEvents = [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ]

    fullscreenEvents.forEach(event =>
      document.addEventListener(event, this.handleFullscreenChange.bind(this)),
    )
  }

  removeFullscreenListeners() {
    const fullscreenEvents = [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ]

    fullscreenEvents.forEach(event =>
      document.removeEventListener(
        event,
        this.handleFullscreenChange.bind(this),
      ),
    )
  }

  handleFullscreenChange() {
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    const isPlayerFullscreen = fullscreenElement === this.frameTarget

    this.dispatch(isPlayerFullscreen ? "enterFullscreen" : "exitFullscreen", {
      detail: { isFullscreen: isPlayerFullscreen },
    })
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      this.timeValue = this.time
      this.dispatch("timer", { detail: { time: this.time } })
      this.checkPlaybackPercentage()
    }, 1000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  checkPlaybackPercentage() {
    const currentTime = this.time
    if (!this.fiftyPercentRaised && currentTime >= this.durationValue * 0.5) {
      this.fiftyPercentRaised = true
      this.dispatch("fiftyPercent", { detail: { time: currentTime } })
    }
    if (
      !this.seventyFivePercentRaised &&
      currentTime >= this.durationValue * 0.75
    ) {
      this.seventyFivePercentRaised = true
      this.dispatch("seventyFivePercent", { detail: { time: currentTime } })
    }
  }

  play = () => {
    this.player.playVideo()
  }

  pause = () => {
    this.player.pauseVideo()
  }

  stop = () => {
    this.player.stopVideo()
  }

  seek = seconds => {
    this.player.seekTo(seconds)
  }

  get duration() {
    return this.player?.getDuration()
  }

  get loaded() {
    return this.player.getVideoLoadedFraction()
  }

  get player() {
    return this.youtube
  }

  get time() {
    return parseInt(this.player.getCurrentTime())
  }

  get stateNames() {
    return {
      "-1": "unstarted",
      0: "ended",
      1: "playing",
      2: "paused",
      3: "buffering",
      5: "video cued",
    }
  }
}
