import { Controller } from "@hotwired/stimulus"
import { useCapsulePlayer } from "./useCapsulePlayer"

// Connects to data-controller="capsule--player"
export default class extends Controller {
  static values = {
    slug: String,
    id: String,
    width: { type: Number, default: 200 },
    height: { type: Number, default: 268 },
    animated: { type: Boolean, default: true },
  }

  connect() {
    if (!this.hasSlugValue || !this.hasIdValue) {
      return
    }

    useCapsulePlayer(this)

    this.element.style.width = `${this.widthValue}px`
    this.element.style.height = `${this.heightValue}px`
  }

  player({ el }) {
    window.capsulePlayer({
      slug: this.slugValue,
      id: this.idValue,
      el,
      width: this.widthValue,
      height: this.heightValue,
      animated: this.animatedValue,
    })
  }

  injectScripts() {
    if (typeof window.capsulePlayer === "function") {
      return
    }

    // prettier-ignore
    (function(c,a,p,s,u,l,e){c["_capsulePlayerIdent"]=p;c[p]=c[p]||function(){
      (c[p].q=c[p].q||[]).push(arguments);e=a.querySelector("script[data-capsule='"+p+"']");
      if(!e){u=a.createElement("script");u.async=1;u.src=s;u.dataset["capsule"]=p;
      l=a.currentScript?.parentElement||a.body;l.appendChild(u);}}})
      (window,document,"capsulePlayer","https://embed.capsule.video/player.js");
  }
}
