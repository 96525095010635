import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/swiper-bundle"

// Webpack needs actual CSS extensions specified.
import "swiper/swiper-bundle.css"
import "./cardlist_component"

// Connects to data-controller="sliders--cardlist"
export default class extends Controller {
  static targets = ["pagination", "nextButton", "prevButton"]

  static values = {
    options: Object,
  }

  connect() {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
        disabledClass: "hidden",
        enabled: false,
      },
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions() {
    return {
      direction: "horizontal",
      slidesPerView: 1,
      centeredSlides: false,
      spaceBetween: 10,
      breakpoints: {
        /* Tailwind sm: breakpoint */
        640: {
          slidesPerView: "auto",
          spaceBetween: 30,
          navigation: {
            enabled: true,
          },
        },
      },
    }
  }
}
