import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="active-link"
export default class extends Controller {
  connect() {
    this.watchLinks()
  }

  makeActive(event) {
    const activeLink = this.activeLink
    const clickedLink = event.currentTarget

    if (activeLink) {
      activeLink.setAttribute("data-active", "false")
    }

    clickedLink.setAttribute("data-active", "true")
  }

  watchLinks() {
    this.links.forEach(link => {
      link.addEventListener("click", event => this.makeActive(event), true)
    })
  }

  get links() {
    return this.element.querySelectorAll("a[href]")
  }

  get activeLink() {
    return this.element.querySelector('a[data-active="true"]')
  }
}
