import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["group", "button"]

  connect() {
    this.groups = this.groupTargets
    this.buttons = this.buttonTargets
  }

  toggle(event) {
    const clickedButton = event.currentTarget
    const isActive = clickedButton.dataset.active === "true"
    const groupName = clickedButton.dataset.group
    console.debug(
      "GroupController#toggle",
      "clickedButton",
      clickedButton,
      "isActive",
      isActive,
      "groupName",
      groupName,
    )
    if (isActive) {
      console.debug(
        "GroupController#toggle",
        "Resetting all groups and buttons",
      )
      // Reset all groups and buttons
      this.groups.forEach(group => group.classList.remove("!opacity-30"))
      this.buttons.forEach(button => button.classList.remove("!opacity-30"))

      // Deactivate the clicked button
      clickedButton.dataset.active = "false"
    } else {
      // Add opacity to all groups
      this.groups.forEach(group => group.classList.add("!opacity-30"))

      // Remove opacity from the active group
      this.groups
        .filter(group => group.dataset.group === groupName)
        .forEach(group => group.classList.remove("!opacity-30"))

      // Add opacity to all buttons
      this.buttons.forEach(button => button.classList.add("!opacity-30"))
      // Deactivate all other buttons
      this.buttons.forEach(button => (button.dataset.active = "false"))

      // Remove opacity from the active button
      clickedButton.classList.remove("!opacity-30")
      clickedButton.dataset.active = "true"
    }
  }
}
