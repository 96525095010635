import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-picker"
export default class extends Controller {
  static targets = [
    "bgColor",
    "bgHex",
    "textColor",
    "textHex",
    "preview",
    "contrast",
  ]

  connect() {
    this.bgColor = this.bgColorTarget.value
    this.textColor = this.textColorTarget.value

    this.onColorChanged()
  }

  isValidColor(color) {
    return /(^#[0-9A-F]{6}$)/i.test(color)
  }

  updateColor(event) {
    const color = event.target?.value
    if (!this.isValidColor(color)) {
      return
    }

    if (event.target === this.bgColorTarget) {
      this.bgColor = color
    } else if (event.target === this.textColorTarget) {
      this.textColor = color
    }

    this.onColorChanged()
  }

  onColorChanged() {
    // Update color fields
    const colorTargets = [
      { target: this.bgColorTarget, value: this.bgColor },
      { target: this.bgHexTarget, value: this.bgColor },
      { target: this.textColorTarget, value: this.textColor },
      { target: this.textHexTarget, value: this.textColor },
    ]

    colorTargets.forEach(({ target, value }) => {
      if (target.value !== value) {
        target.value = value
      }
    })

    // Update preview
    if (this.bgColor) this.previewTarget.style.backgroundColor = this.bgColor
    if (this.textColor) this.previewTarget.style.color = this.textColor

    this.updateContrast()
  }

  updateContrast() {
    if (this.hasContrastTarget) {
      const contrastRatio = this.calculateContrast(this.bgColor, this.textColor)
      this.contrastTarget.textContent = `Contrast: ${contrastRatio.toFixed(2)}`
    }
  }

  // https://www.w3.org/TR/WCAG20/#relativeluminancedef
  calculateLuminance(color) {
    const rgb = this.hexToRgb(color)
    const luminance = rgb.map(c => {
      const cNormalized = c / 255
      return cNormalized <= 0.03928
        ? cNormalized / 12.92
        : Math.pow((cNormalized + 0.055) / 1.055, 2.4)
    })
    return 0.2126 * luminance[0] + 0.7152 * luminance[1] + 0.0722 * luminance[2]
  }

  calculateContrast(color1, color2) {
    const luminance1 = this.calculateLuminance(color1)
    const luminance2 = this.calculateLuminance(color2)
    const brightest = Math.max(luminance1, luminance2)
    const darkest = Math.min(luminance1, luminance2)
    return (brightest + 0.05) / (darkest + 0.05)
  }

  hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null
  }
}
